@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Black.eot');
	src: url('../fonts/Onest-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Black.woff2') format('woff2'),
		url('../fonts/Onest-Black.woff') format('woff'),
		url('../fonts/Onest-Black.ttf') format('truetype'),
		url('../fonts/Onest-Black.svg#Onest-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-SemiBold.eot');
	src: url('../fonts/Onest-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-SemiBold.woff2') format('woff2'),
		url('../fonts/Onest-SemiBold.woff') format('woff'),
		url('../fonts/Onest-SemiBold.ttf') format('truetype'),
		url('../fonts/Onest-SemiBold.svg#Onest-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Medium.eot');
	src: url('../fonts/Onest-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Medium.woff2') format('woff2'),
		url('../fonts/Onest-Medium.woff') format('woff'),
		url('../fonts/Onest-Medium.ttf') format('truetype'),
		url('../fonts/Onest-Medium.svg#Onest-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Bold.eot');
	src: url('../fonts/Onest-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Bold.woff2') format('woff2'),
		url('../fonts/Onest-Bold.woff') format('woff'),
		url('../fonts/Onest-Bold.ttf') format('truetype'),
		url('../fonts/Onest-Bold.svg#Onest-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-ExtraBold.eot');
	src: url('../fonts/Onest-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-ExtraBold.woff2') format('woff2'),
		url('../fonts/Onest-ExtraBold.woff') format('woff'),
		url('../fonts/Onest-ExtraBold.ttf') format('truetype'),
		url('../fonts/Onest-ExtraBold.svg#Onest-ExtraBold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Light.eot');
	src: url('../fonts/Onest-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Light.woff2') format('woff2'),
		url('../fonts/Onest-Light.woff') format('woff'),
		url('../fonts/Onest-Light.ttf') format('truetype'),
		url('../fonts/Onest-Light.svg#Onest-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Thin.eot');
	src: url('../fonts/Onest-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Thin.woff2') format('woff2'),
		url('../fonts/Onest-Thin.woff') format('woff'),
		url('../fonts/Onest-Thin.ttf') format('truetype'),
		url('../fonts/Onest-Thin.svg#Onest-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-Regular.eot');
	src: url('../fonts/Onest-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-Regular.woff2') format('woff2'),
		url('../fonts/Onest-Regular.woff') format('woff'),
		url('../fonts/Onest-Regular.ttf') format('truetype'),
		url('../fonts/Onest-Regular.svg#Onest-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Onest';
	src: url('../fonts/Onest-ExtraLight.eot');
	src: url('../fonts/Onest-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Onest-ExtraLight.woff2') format('woff2'),
		url('../fonts/Onest-ExtraLight.woff') format('woff'),
		url('../fonts/Onest-ExtraLight.ttf') format('truetype'),
		url('../fonts/Onest-ExtraLight.svg#Onest-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-Regular.eot');
// 	src: url('../fonts/GolosText-Regular.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-Regular.woff2') format('woff2'),
// 		url('../fonts/GolosText-Regular.woff') format('woff'),
// 		url('../fonts/GolosText-Regular.ttf') format('truetype'),
// 		url('../fonts/GolosText-Regular.svg#GolosText-Regular') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-SemiBold.eot');
// 	src: url('../fonts/GolosText-SemiBold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-SemiBold.woff2') format('woff2'),
// 		url('../fonts/GolosText-SemiBold.woff') format('woff'),
// 		url('../fonts/GolosText-SemiBold.ttf') format('truetype'),
// 		url('../fonts/GolosText-SemiBold.svg#GolosText-SemiBold') format('svg');
// 	font-weight: 600;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-Bold.eot');
// 	src: url('../fonts/GolosText-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-Bold.woff2') format('woff2'),
// 		url('../fonts/GolosText-Bold.woff') format('woff'),
// 		url('../fonts/GolosText-Bold.ttf') format('truetype'),
// 		url('../fonts/GolosText-Bold.svg#GolosText-Bold') format('svg');
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-Medium.eot');
// 	src: url('../fonts/GolosText-Medium.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-Medium.woff2') format('woff2'),
// 		url('../fonts/GolosText-Medium.woff') format('woff'),
// 		url('../fonts/GolosText-Medium.ttf') format('truetype'),
// 		url('../fonts/GolosText-Medium.svg#GolosText-Medium') format('svg');
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-Black.eot');
// 	src: url('../fonts/GolosText-Black.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-Black.woff2') format('woff2'),
// 		url('../fonts/GolosText-Black.woff') format('woff'),
// 		url('../fonts/GolosText-Black.ttf') format('truetype'),
// 		url('../fonts/GolosText-Black.svg#GolosText-Black') format('svg');
// 	font-weight: 900;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Golos';
// 	src: url('../fonts/GolosText-ExtraBold.eot');
// 	src: url('../fonts/GolosText-ExtraBold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/GolosText-ExtraBold.woff2') format('woff2'),
// 		url('../fonts/GolosText-ExtraBold.woff') format('woff'),
// 		url('../fonts/GolosText-ExtraBold.ttf') format('truetype'),
// 		url('../fonts/GolosText-ExtraBold.svg#GolosText-ExtraBold') format('svg');
// 	font-weight: bold;
// 	font-style: normal;
// 	font-display: swap;
// }