body:has(.dashbord._open) {
	overflow: hidden;
}

.dashbord {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	overflow: hidden;
	display: none;

	&._open {
		display: block;
	}

	&__scroll {
		left: 0;
		position: absolute;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 127px 0 97px;

		@include mediaMedium() {
			padding: 72px 0 72px;
		}
	}

	&__prev {
		left: -80px;
	}

	&__next {
		right: -80px;
	}



	&__prev,
	&__next {
		top: 50%;
		margin-top: -27px;
		position: absolute;
		width: 55px;
		height: 55px;
		border: 1px solid #BFBEC1;
		cursor: pointer;
		z-index: 10;
		backdrop-filter: blur(13.740458488464355px);
		border-radius: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: #fff;
		background: rgba($color: #000000, $alpha: 0.2);

		@media(max-width:1200px) {
			display: none;

		}

		svg {
			width: 20px;
			height: 20px;

		}
	}

	&__slider {

		overflow: visible;
		@include clamp('max-width', 1189px, 1604px, 1366px, 1920px);


		@media(max-width:1366px) {
			max-width: calc(100vw - 200px);
		}

		@media(max-width:1200px) {
			max-width: calc(100vw - 60px);
		}

		.swiper-slide {

			position: relative;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 42.9% auto;
		gap: 15px;
		min-height: calc(100svh - 97px - 127px);

		@include mediaMedium() {
			min-height: calc(100svh - 72px - 72px);
			gap: 10px;
			grid-template-columns: 42.2% auto;
		}

		@media(max-width:992px) {
			grid-template-columns: 1fr;
		}
	}

	&__map-block {
		display: flex;
		flex: 1;
		position: relative;
		flex-direction: column;

		p {
			font-size: 12px;
		}

		.btn {
			background: #fff;
			text-align: center;
			color: var(--c-text);
			position: relative;
			z-index: 4;
		}
	}

	&__map-block {
		overflow: hidden;

		@media(max-width:500px) {
			display: none;
		}
	}

	&__close {
		position: absolute;

		svg {
			width: 24px;
			height: 24px;
		}

		top: -24px;
		right: -24px;
	}

	&__map {

		margin: auto 0;



		&-bg {
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-52%) scale(1.0) translateY(-47%);
			mix-blend-mode: difference;


		}

		&-svg {
			width: 100%;
			position: relative;
			z-index: 4;
		}
	}

	&__left-grid {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@include mediaMedium() {
			gap: 10px;
		}
	}

	&__right {
		flex-direction: column;
		display: flex;

		@media(max-width:992px) {
			&>h4 {
				display: none;
			}

			padding: 0 !important;
			background: none !important;
			backdrop-filter: none !important;
		}
	}

	&__blur-block {
		padding: 20px;
		color: #fff;
		border-radius: 40px;
		backdrop-filter: blur(82.4427490234375px);
		background: linear-gradient(134deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
	}

	&__right-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		gap: 14px;
		flex: 1;


		@include mediaMedium() {
			gap: 10px;
		}

		@media(max-width:992px) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr;
		}

		@media(max-width:500px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr;
		}
	}

	&__path {
		background: #202c41;
		display: none;
		aspect-ratio: 1/1;
		color: #fff;
		border-radius: 20px;
		padding: 10px;
		order: -1;
		display: none;
		flex-direction: column;

		&-line-1 {
			font-weight: 500;
			font-size: 24px;
			letter-spacing: -0.03em;
		}

		&-line-2 {
			font-weight: 300;
			font-size: 10px;
			line-height: 160%;
			letter-spacing: -0.02em;
			margin-bottom: auto;
		}

		&-line-3 {
			font-size: 10px;
			line-height: 140%;
			letter-spacing: -0.02em;
		}

		@media(max-width:500px) {
			display: flex;
		}
	}

	&__item {
		overflow: hidden;
		border-radius: 40px;
		backdrop-filter: blur(82.4427490234375px);
		position: relative;
		-webkit-backdrop-filter: blur(82.4427490234375px);
		// background: linear-gradient(134deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
		padding: 20px;
		color: #fff;

		@media(max-width:500px) {
			padding: 10px;
			border-radius: 20px;
		}

		@media(min-width:500px) and (max-width:992px) {
			&:nth-child(1) {
				order: 1;
			}

			&:nth-child(2) {
				order: 2;
			}

			&:nth-child(3) {
				order: 3;
			}

			&:nth-child(4) {
				order: 4;
			}

			&:nth-child(5) {
				order: 6;
			}

			&:nth-child(6) {
				order: 5;
			}

			&:nth-child(7) {
				order: 7;
			}

			&:nth-child(8) {
				order: 8;
			}
		}

		&[data-type="link"] {
			background: var(--c-contrast);
			backdrop-filter: initial;
			position: relative;

			@media(max-width:992px) {
				grid-column: span 1;
				aspect-ratio: 1;
			}

		}

		&[data-type="img-right"] {
			display: flex;
			flex-direction: row;


		}

		&[data-type="full-bg"] {
			@media(max-width:992px) {
				grid-column: span 1;
				aspect-ratio: 1;
			}
		}

		&[data-type="full-bg-big"] {
			grid-column: span 2;
			grid-row: span 2;

			@media(max-width:992px) {
				grid-column: span 1;
				aspect-ratio: 1;
				grid-row: span 1
			}
		}


		&[data-type="img-right2"],
		&[data-type="img-right"] {
			grid-column: span 2;

			@media(max-width:992px) {
				grid-column: span 3;
				min-height: 178px;
			}

			@media(max-width:500px) {
				min-height: initial;
			}
		}

		&[data-type="img-right"] {
			@media(max-width:500px) {
				grid-column: span 1;
				aspect-ratio: 1;
				backdrop-filter: blur(60px);
				background: linear-gradient(134deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
			}
		}

		&[data-type="img-right2"] {
			@media(max-width:500px) {
				grid-column: span 2;
				min-height: 129px;
			}
		}


	}

	&__item-bg {
		img {
			transition: transform 1s;

			.dashbord__item:hover & {
				transform: scale(1.05);
			}
		}

		[data-type="img-right"] & {
			img {
				position: absolute;
				left: 50%;
				bottom: 0;
				max-width: 135px;

				@media(max-width:992px) {
					max-width: 190px;
					left: initial;
					right: -24px;
					bottom: 10px;
				}

				@media(max-width:500px) {
					max-width: 90px;
					right: -0px;
				}
			}
		}

		[data-type="img-right2"] & {
			position: absolute;
			width: 50%;
			height: 80%;
			right: 0;
			bottom: 0;

			@media(max-width:992px) {
				height: 100%;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: left bottom;

				@media(max-width:992px) {
					object-fit: contain;
					object-position: right bottom;

				}
			}
		}

		[data-type="full-bg-big"] &,
		[data-type="full-bg"] & {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				left: 0;
				top: 0;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__item-link {
		display: flex;
		position: relative;
		z-index: 1;
		flex-direction: row;
		color: #fff;
		font-weight: 500;
		font-size: 20px;
		line-height: 151%;
		text-decoration: none;

		@include mediaMedium {
			font-weight: 500;
			font-size: 14px;
			line-height: 143%;
		}

		&::after {
			position: absolute;
			left: -50%;
			top: -50%;
			transform: translateX(-50%) transformY(-50%);
			width: 100vmax;
			height: 100vmax;
			display: block;
			content: '';
		}

		[data-color="black"] & {
			color: var(--c-text);
		}

		[data-type="link"] & {
			position: initial;
		}

		svg {
			width: 30px;
			height: 30px;
			transition: transform 0.3s;

			@include mediaMedium {
				width: 22px;
				height: 22px;
			}

			.dashbord__item:hover & {
				transform: rotate(45deg);
			}

			[data-type="link"] & {
				width: 60px;
				height: 60px;
				right: 20px;
				bottom: 20px;
				position: absolute;

				@include mediaMedium {
					width: 44px;
					height: 44px;
					right: 6px;
					bottom: 6px;
				}
			}
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;


		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media(max-width:992px) {

			&::after {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				backdrop-filter: blur(60px);
				background: linear-gradient(134deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
				content: '';
				display: block;
				z-index: 5;
			}
		}
	}
}