.breadcrumbs {
	margin-bottom: 30px;

	@include mediaTablet {
		margin-bottom: 22px;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__item {
		color: #828d9e;
		font-weight: 400;
		font-size: 14px;
		line-height: 129%;
		letter-spacing: -0.01em;

		@include mediaMedium {
			font-size: 12px;
		}

		a {
			color: #828d9e;
			text-decoration: none;

			&:hover {
				color: var(--c-contrast);
			}
		}

		&::after {
			display: inline-block;
			background: #828d9e;
			border-radius: 50%;
			content: '';
			margin: 0 7px 3px;
			width: 3px;
			height: 3px;

			@include mediaMedium {
				margin-bottom: 2px;
			}
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

}