.afisha-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 72px 16px;

	@include mediaMedium {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 40px 10px;
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
		gap: 40px 12px;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}
}

.afisha-preview {
	position: relative;

	&__date {
		display: flex;
		flex-direction: row;
		gap: 10px;
		margin-bottom: 10px;
		align-items: baseline;
	}

	&__date-days {
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 64px;
		line-height: 100%;
		letter-spacing: -0.03em;
		@include clampf(48px, 64px, 1366px, 1920px);

		@media(max-width:1366px) {
			@include clampf(32px, 48px, 768px, 1366px);
		}

		@media(max-width:768px) {
			@include clampf(24px, 32px, 360px, 768px);
		}
	}

	&__date-month {
		font-weight: 300;
		font-size: 16px;
		line-height: 100%;
		letter-spacing: -0.01em;
		color: var(--c-gray2);

		@include mediaTablet {
			font-size: 14px;
		}
	}

	&__img-wrap {
		margin-bottom: 20px;
		position: relative;
		aspect-ratio: 438/290;
		overflow: hidden;

		@include mediaMedium() {
			aspect-ratio: 422/278;
		}

		@include mediaTablet() {
			aspect-ratio: 348/230;
		}

		@include mediaMobile() {
			aspect-ratio: 320/240;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.4s;

			.afisha-preview:hover & {
				transform: scale(1.02);
			}
		}


	}

	&__img-mask-wrap {
		transition: transform 0.4s;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		.afisha-preview:hover & {
			transform: scale(1.02);
		}
	}

	&__img-mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: none !important;
	}

	.afisha-preview__img-wrap .afisha-preview__img {
		left: 50%;
		top: 50%;
		width: 37%;
		height: initial;
		aspect-ratio: 161 / 227;
		position: absolute;
		object-fit: cover;
		transform: translateX(-69%) translateY(-47%) !important;

		@include mediaMobile() {
			width: 42%;
		}

	}

	&__title {
		text-decoration: none;
		color: var(--c-text);

		.block[data-bg="blue"] & {
			color: #fff;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	&__link {
		border-radius: 16px;
		padding: 10px 16px;
		display: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: var(--c-text);
		position: absolute;
		left: 20px;
		width: calc(100% - 40px);
		bottom: 20px;
		background: #fff;
		text-decoration: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;

		@include mediaMedium {
			font-size: 14px;
			line-height: 143%;
			letter-spacing: -0.01em;
		}

		@include mediaMobile {
			display: flex;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}

		svg {
			margin-left: auto;
			max-width: 22px;
			max-height: 22px;
			transition: transform 0.5s;

			.afisha-preview:hover & {
				transform: rotate(45deg);
			}
		}
	}
}