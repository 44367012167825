.sertificat-slider {
	margin-bottom: 16px;
	padding-left: var(--inner-padding);
	padding-right: var(--inner-padding);
	margin-left: calc(var(--inner-padding) * -1);
	margin-right: calc(var(--inner-padding) * -1);

	.swiper-wrapper {
		@include mediaMobile {
			flex-direction: column !important;
			transform: none !important;
			gap: 10px;

		}
	}

	&__icon {
		width: 34px;
		height: 34px;
		color: var(--c-contrast);
		margin-bottom: 50px;
	}

	&__title {
		margin-top: auto;
	}

	.btn-arrow {
		min-width: initial !important;
		width: 100%;
	}

	.swiper-slide {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		height: auto;
		border-radius: 30px;
		padding: 20px;
		background-color: var(--c-gray);

		@media(max-width:930px) {
			max-width: 258px;
		}

		@include mediaMobile {
			max-width: 100%;
		}
	}
}

.sertificat {
	overflow: hidden;
	position: relative;
	min-height: 38.542vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;

	@include mediaTablet {
		min-height: 41.5vw;
	}

	@include mediaMobile {
		min-height: 522px;
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 3;

		img,
		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.4);
			display: block;
			content: '';
		}
	}

	&__content {
		position: relative;
		z-index: 5;
		color: #fff;
		padding: 50px 40px;

		.icon-subtitle {
			color: #fff;
		}
	}
}