.seating-chart-block {
	border-radius: 30px;
	background: #fff;
	padding-top: 100px;
	padding-bottom: 100px;
	margin-bottom: 110px;

	@include mediaMobile {
		background: transparent;
		border-radius: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

.seating-chart {
	padding: 30px;
	padding-bottom: 0;
	border: 1px solid var(--c-border);
	display: block;
	background: #Fff;

	&__icon {
		margin: -19px 0 -7px
	}

	@include mediaMobile {
		display: none;
	}

	table {
		width: 100%;

		strong {
			font-weight: 500;
		}

		tr:first-child td {

			font-size: 14px;
		}

		tr:nth-child(2) {
			td {
				padding: 9px 0;
			}

			strong {
				font-size: 14px;
			}
		}

		tr {
			td {
				border-top: 1px solid var(--c-border);
				padding: 10px;
				text-align: center;
				vertical-align: middle;
				font-weight: 300;
				font-size: 16px;
				line-height: 150%;
				letter-spacing: -0.01em;
				text-align: center;

				&:first-child {
					text-align: left;
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}

			&:first-child {
				td {
					border-top: 0px;
				}
			}

			&:last-child {
				td {
					border-bottom: 0px;
				}
			}
		}
	}

}

.seating-chart-mob {
	background: #fff;
	padding: 30px 20px;
	margin-bottom: 8px;
	display: none;

	@include mediaMobile {
		display: block;
	}

	&__headline {
		@include mediaMobile {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}

	&__line {
		display: grid;
		gap: 5px;
		align-items: center;
		grid-template-columns: 40px auto 40%;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		font-weight: 300;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
	}

	&__text {
		font-weight: 600;
		font-size: 14px;
		line-height: 171%;
		letter-spacing: -0.01em;
		text-align: right;
	}
}