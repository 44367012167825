.object-slider {
	.block__head-nav {
		display: flex;
	}

	.btn-arrow {
		min-width: initial;
	}

	@media (min-width: #{$tablet-width}) {

		&:has(.object-preview._hover) .object-preview {
			width: calc((((100vw - (var(--inner-padding) * 2)) - (((100vw - (var(--inner-padding) * 2)) / var(--lenght)) * 1.8) + 16px) / (var(--lenght) - 1)) - 16px) !important;
		}

		&:has(.object-preview._hover) .object-preview._hover {
			width: calc(((100vw - (var(--inner-padding) * 2))/var(--lenght)) * 1.8) !important;
		}

		.object-preview {
			transition: width 0.3s;


			width: calc(((100vw - (var(--inner-padding) * 2))/var(--lenght))) !important;

			&._hover {}
		}


	}

	&__grid {
		display: flex;
		gap: 10px;
		overflow: hidden;

		@include mediaTablet {
			display: grid;
			gap: 12px;
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media(max-width:800px) {
			grid-template-columns: 1fr 1fr;
		}
	}
}