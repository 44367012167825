.front-top2 {
	min-height: 100svh;
	width: 100vw;
	max-width: 100vw;
	left: 50%;
	position: relative;
	transform: translateX(-50%);
	margin: 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	padding-bottom: 60px;
	padding-top: 110px;



	margin-top: -120px;

	@include mediaMobile {
		margin-top: -114px;
	}

	.traveline {
		.traveline__form {
			bottom: 0;
			left: 50%;
			right: 0;
			transform: translateX(-50%);
			position: relative;
		}
	}

	h1,
	h2 {
		text-align: center;
		@include clampf(64px, 90px, 1366px, 1920px);
		margin-bottom: 20px;

		@media(max-width:1366px) {
			font-size: 64px;
			;
		}

		@media(max-width:768px) {
			@include clampf(24px, 64px, 360px, 768px);
		}
	}

	&__text {
		position: relative;
		z-index: 3;
		color: #fff;

		@include mediaTablet {
			text-align: center;
		}
	}

	&__content {
		display: flex;
		margin-top: auto;
		margin-bottom: auto;
		flex-direction: column;
		position: relative;

	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img,
		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			opacity: 0.4;
			background: #000;
		}
	}
}

.front-info-1 {
	display: grid;
	grid-template-columns: auto 39%;
	gap: 68px;
	max-width: 1494px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
	padding-right: 0;

	@include mediaMedium() {
		max-width: 1067px;
	}

	@include mediaTablet() {
		grid-template-columns: 1fr;
		gap: 20px;
		max-width: 528px;
		margin-left: auto;
		padding: 0;
		margin-right: auto;
	}

	.h2 {
		line-height: 116%;
		letter-spacing: -0.03em;
	}

	&__photos {
		padding-top: 100px;
		padding-right: 25%;
		position: relative;
		max-width: 739px;

		@include mediaTablet() {
			margin-top: 20px;
		}

		@include clamp('padding-top', 80px, 100px,
			1366px,
			1920px);

		@media(max-width:1366px) {
			@include clamp('padding-top', 72px, 80px,
				768,
				1366px);
		}

		@media(max-width:768px) {
			@include clamp('padding-top', 43px, 72px,
				360px,
				768px);
		}



		img:last-child {
			max-width: 35%;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}