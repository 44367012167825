.object-preview {
	position: relative;
	min-height: 580px;
	overflow: hidden;

	@include mediaMedium {
		min-height: 500px;
	}

	@include mediaTablet {
		min-height: 400px;
	}

	@include mediaMobile {
		min-height: initial;
		border-radius: 0 !important;
	}

	&__img-wrap {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		@include mediaMobile() {
			position: relative;
			aspect-ratio: 1/1;
			margin-bottom: 10px;
			width: initial;
			height: initial;
		}

		img {
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: transform 0.5s;

			.object-preview:hover & {
				transform: scale(1.01);
			}
		}
	}

	&__btns {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 20px;
		z-index: 3;
		width: 100%;

		@include mediaMobile() {
			position: relative;
			padding: 0;

			.btn-arrow {
				padding: 0;
				background: transparent !important;
				border-radius: 0;
				border: 0 solid !important;

				[data-bg="blue"] & {
					color: #fff !important;
				}
			}
		}
	}

	.btn-arrow {
		width: 100%;
		background: #fff;
		color: var(--c-text) !important;

		&::after {
			position: absolute;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			top: 50%;
			width: 100vmax;
			height: 100vmax;
			display: block;
			content: '';
		}
	}
}