.info-1 {
	margin-top: 40px;

	&__main-img {
		aspect-ratio: 1800/744;
		border-radius: 30px;
		object-fit: cover;


		@include mediaMedium() {
			aspect-ratio: 1286/532;
		}

		@include mediaTablet() {
			aspect-ratio: 708/318;
		}

		@include mediaMobile() {
			aspect-ratio: none;
			min-height: 320px;
		}
	}

	&__swiper {

		.info-1.info-1 & {
			margin-top: 10px;
			padding-left: var(--inner-padding);
			padding-right: var(--inner-padding);
			margin-left: calc(var(--inner-padding) * -1);
			margin-right: calc(var(--inner-padding) * -1);
			overflow: hidden !important;
		}

		@include mediaMobile {
			margin-top: 8px;
		}

		&>.swiper-wrapper>.swiper-slide {
			min-height: 356px;

			@media(max-width:1130px) {
				max-width: 254px;
				min-height: 344px;
			}
		}

		h3 {}
	}

	.swiper-slide {
		height: auto;
	}

	&__slider-2 {
		border-radius: 30px;

		.info-1.info-1 & {
			overflow: hidden !important;
		}

		[data-bg="green"] & .swiper-slide {
			background: #3E7702;
		}

		[data-bg="yellow"] & .swiper-slide {
			background: #FFA800;
		}

		.swiper-slide {
			color: #fff;
			background: var(--c-blue);
			display: flex;
			flex-direction: column;
			border-radius: 30px;
			padding: 30px 20px;
			padding-bottom: 63px;
		}

		p {
			font-size: 14px;

			color {
				color: var(--c-gray2);
			}

			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			big {
				font-weight: 400;
				font-size: 48px;
				line-height: 112%;
				letter-spacing: -0.03em;

				@include clampf(48px, 64px, 1366px, 1920px);


				@media(max-width:1366px) {
					@include clampf(32px, 48px, 768px, 1366px);
				}
			}
		}
	}

	&__slider-2-pagi {
		display: flex;
		left: 20px;
		bottom: 30px;
		width: calc(100% - 40px);
		top: initial;
		position: absolute;
		z-index: 11;
		gap: 5px;

		.swiper-pagination-bullet {
			height: 1px;
			background: rgba(255, 255, 255, 0.3);
			position: relative;
			border-radius: 0;
			flex: 1;
			opacity: 1;

			&::after {
				position: absolute;
				left: 0;
				top: 0;
				width: calc(100% * var(--progress));
				height: 100%;
				transition: width 0.3s;
				content: '';
				display: none;
				background: #fff;
			}

			&.swiper-pagination-bullet-active {
				opacity: 1;

				&::after {
					display: block;
				}
			}
		}
	}

	&__slide-img {
		position: relative;
		border-radius: 30px;
		overflow: hidden;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__slide-text {
		background: var(--c-gray);
		display: flex;
		flex-direction: column;
		border-radius: 30px;
		padding: 30px 20px;

		hr {
			border-color: var(--c-gray);
			height: 2px;
			width: 100%;
			opacity: 0.3;
			margin: 20px 0;
		}

		dl {

			dd,
			dt {
				font-size: 14px;
				line-height: 143%;
				letter-spacing: -0.01em;
			}
		}

		ul {
			li {
				font-size: 14px;
				line-height: 143%;
				letter-spacing: -0.01em;

				&::after {
					left: 12px;
					top: 7px;
				}
			}
		}

		p {
			font-size: 14px;

			color {
				color: var(--c-gray2);
			}

			big {
				font-weight: 300;
				@include clampf(48px, 64px, 1366px, 1920px);


				@media(max-width:1366px) {
					@include clampf(32px, 48px, 768px, 1366px);
				}

				line-height: 112%;
				letter-spacing: -0.03em;


			}
		}


	}

	._mb {
		margin-bottom: auto;
	}
}