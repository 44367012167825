._filter-hidden,
._page-hidden {
	display: none !important;
}

.filter {

	h2+&,
	h1+&,
	.h1+& {
		margin-top: -10px;

		@include mediaTablet {
			margin-top: 0;
		}

		@include mediaMobile {
			margin-top: 21px;
		}
	}

	&__head {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;

		.btn-border {

			[data-bg="blue"] & {
				color: #fff;
				border-color: #fff;

				&:hover {
					border-color: #fff;
					background: #fff;
					color: var(--c-text);
				}
			}
		}

		.btn-arrow {
			min-width: initial;
			max-width: initial;
			white-space: nowrap;
		}

		.filter__filters {
			margin-bottom: 0;
			margin-right: auto;
		}
	}

	&__head-right {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}

	&__head-nav {
		display: flex;
		flex-direction: row;
		color: var(--c-text);
		gap: 4px;
		line-height: 0;
		margin-left: auto;



		button {
			background: #fff;
			width: 42px;
			height: 42px;
			border-radius: 16px;

			.block[data-bg="white"] & {
				box-shadow: 0 0 0 1px var(--c-text);
			}

			&.swiper-button-disabled {
				pointer-events: none;
				color: #828d9e;

			}

			&:hover {
				color: var(--c-contrast);
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__filters {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		max-width: calc(100% + var(--inner-padding)*2);
		overflow-y: hidden;
		overflow-x: auto;
		margin-left: calc(-1 * var(--inner-padding));
		margin-right: calc(-1 * var(--inner-padding));
		padding-left: calc(var(--inner-padding));
		padding-right: calc(var(--inner-padding));

		@include mediaMedium {
			margin-bottom: 31px;
		}

		@include mediaTablet {
			margin-bottom: 42px;
		}

		@include mediaMobile {
			margin-bottom: 19px;
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	&__filter {
		padding: 8px 16px;
		font-family: var(--font-family);
		font-weight: 400;
		white-space: nowrap;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.00em;
		color: #6e6e71;
		transition: background 0.4s, color 0.4s;
		border-radius: var(--border-radius);
		display: inline-block;
		text-decoration: none;

		&::after {
			padding-left: 0;
			display: none;
		}

		&:hover {
			color: #000;

			[data-bg="blue"] & {
				color: #fff;
			}
		}

		&._active {
			background: var(--c-text) !important;
			color: #fff !important;

			[data-bg="blue"] & {
				background: #fff !important;
				color: var(--c-text) !important;
			}
		}
	}

	&__more {
		margin-top: 40px;
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		display: flex;
		gap: 7px;
		margin-left: auto;
		margin-right: auto;
		align-items: center;

		svg {
			width: 9px;
		}

		.filter._not-more & {
			display: none;
		}
	}
}