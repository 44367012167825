.header-top {
	background-color: rgba($color: #828d9e, $alpha: 0.1);
	padding: 9px 0;
	transition: background 0.4s;
	z-index: 15;
	position: relative;

	&__location-wrap {
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-top: -10px;
		margin-bottom: -10px;
	}

	&__location-drop {
		position: absolute;
		left: 0;
		top: 28px;
		background: #fff;
		border: 1px solid #ced3df;
		border-radius: 16px;
		padding: 20px 16px;
		box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
		z-index: 3;
		display: flex;
		flex-direction: column;
		gap: 5px;
		opacity: 0;
		touch-action: none;
		pointer-events: none;
		transform: scale(0.95) translateY(30px);
		transition: opacity 0.3s, transform 0.3s;

		.header-top__location-wrap:hover & {
			transform: scale(1) translateY(0);
			touch-action: initial;
			pointer-events: initial;
			opacity: 1;
		}

		a {
			font-weight: 400;
			white-space: nowrap;
			font-size: 14px;
			letter-spacing: 0em;
			color: #6e6e71;
			text-decoration: none;

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	// .header-top__row
	&__row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 31px;


	}

	// .header-top__location
	&__location {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 3px;
		color: var(--c-text);
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.01em;
	}

	// .header-top__menu
	&__menu {
		display: flex;
		flex-direction: row;
		gap: 30px;
		margin-right: auto;

		@include mediaMedium {
			padding-left: 32px;
		}

		@include mediaTablet {
			display: none;
		}

		a {
			font-weight: 400;
			font-size: 14px;
			line-height: 129%;
			letter-spacing: -0.01em;
			color: #606981;
			text-decoration: none;

			@include mediaMedium {
				font-size: 12px;
			}

			&:hover {
				color: var(--c-text);
			}
		}
	}

	// .header-top__feedback
	&__feedback {
		position: relative;
		margin-left: auto;

	}

	// .header-top__feedback-btn
	&__feedback-btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;
		color: var(--c-text);
		font-weight: 600;
		font-size: 14px;
		line-height: 129%;
		letter-spacing: -0.02em;

		.header-top__feedback:hover &,
		.header-top__feedback._open & {
			color: var(--c-contrast);
		}

		span {
			@include mediaMedium {
				font-size: 12px;
			}

			@include mediaMobile {
				display: none;
			}
		}
	}



	// .header-top__feedback-popup
	&__feedback-popup {
		position: absolute;
		right: 0;
		top: 0;
		padding-top: 38px;
		opacity: 0;
		touch-action: none;
		pointer-events: none;
		transform: scale(0.95) translateY(30px);
		transition: opacity 0.3s, transform 0.3s;

		.header-top__feedback:hover &,
		.header-top__feedback._open & {
			transform: scale(1) translateY(0);
			touch-action: initial;
			pointer-events: initial;
			opacity: 1;
		}
	}

	// .header-top__feedback-block
	&__feedback-block {
		border: 1px solid #ced3df;
		border-radius: 16px;
		padding: 20px 16px;
		box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
		background: #fff;
		width: 341px;
	}

	// .header-top__feedback-title
	&__feedback-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
	}

	// .header-top__feedback-grid
	&__feedback-grid {
		margin-top: 10px;
		display: grid;
		gap: 10px 30px;
		grid-template-columns: auto auto;
	}

	// .header-top__feedback-item
	&__feedback-item {
		a {
			font-family: var(--font-family);
			font-weight: 400;
			font-size: 12px;
			line-height: 167%;
			letter-spacing: -0.02em;
			color: var(--c-text);
			text-decoration: none;

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	// .header-top__feedback-item-title
	&__feedback-item-title {
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 12px;
		line-height: 167%;
		letter-spacing: -0.02em;
		color: #828d9e;
	}

	// .header-top__feedback-content
	&__feedback-content {}

}


body:has(.front-top2) .header:not(._scroll) {
	background: transparent;
	color: #fff;

	.header__menu-btn-icon {

		&::before,
		&::after {
			background: #fff;
		}
	}

	.header__logo {
		filter: brightness(0) invert(1);


	}

	.header__menu-btn,
	.header-top__feedback-btn,
	.header-top__menu a,
	.header-top__location {
		color: #fff;

		&:hover {
			color: var(--c-contrast) !important;
		}
	}

	.header__menu-first-link {
		color: #fff;
	}

	.header__btn {
		background: #fff;
		color: var(--c-text);

		&:hover {
			color: #fff;
			background: var(--c-contrast);
		}
	}
}

.header {
	z-index: 15;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: #fff;
	transition: background 0.4s, top 0.4s;

	&._scroll {
		top: -110px;

		&._view {
			top: 0;
		}
	}

	body:has(#params[data-bg="gray"]) & {
		background: var(--c-gray);
	}

	// .header__row
	&__row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 44px;
		padding: 12px 0;

		@include mediaMedium {
			gap: 25px;
		}

		@include mediaTablet {
			gap: 25px;
		}

		@include mediaMobile {
			gap: 10px;
		}
	}

	// .header__logo
	&__logo {
		line-height: 0;
		display: block;

		@media(max-width:1300px) {
			margin-right: auto;
		}
	}

	// .header__menu
	&__menu {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 21px;

		margin-right: auto;

		@media(min-width:1300px) {
			.header._not-top & {
				.header__menu-btn-wrap {
					display: none;

					@include mediaTablet {
						display: block;
					}
				}
			}
		}

		@media(max-width:1300px) {
			order: 4;
			margin-right: 0;
		}

		@include mediaMobile {
			gap: 10px;
		}

		&>li>a {
			padding-top: 15px;
			padding-bottom: 15px;
			display: inline-block;
		}

		li {
			&:hover {
				.header__menu-first-link::after {
					margin-top: -2px;
					transform: rotate(135deg);
				}
			}

			position: relative;

		}

		li:not(.header__menu-btn-wrap) {
			@media(max-width:1300px) {

				display: none;
			}
		}
	}

	// .header__menu-btn-wrap
	&__menu-btn-wrap {}

	// .header__menu-btn
	&__menu-btn {
		color: var(--c-contrast);
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		font-weight: 500;
		font-size: 16px;
		line-height: 125%;
		letter-spacing: -0.01em;

		@include mediaMedium() {
			font-size: 14px;
		}

		@include mediaMobile() {
			width: 30px;
			height: 30px;
			border: 1px solid var(--c-contrast);
			border-radius: 50%;
			justify-content: center;

			span {
				display: none;
			}
		}
	}

	// .header__menu-btn-icon
	&__menu-btn-icon {
		width: 12px;
		height: 6px;
		position: relative;
		display: block !important;

		&::after {
			top: 0;
		}

		&::before {
			bottom: 0;
		}

		&::after,
		&::before {
			width: 100%;
			height: 2px;
			display: block;
			content: '';
			left: 0;
			position: absolute;
			background-color: var(--c-contrast);
		}
	}

	// .header__menu-first-link
	&__menu-first-link {
		font-weight: 500;
		font-size: 16px;
		line-height: 125%;
		letter-spacing: -0.01em;
		color: var(--c-text);
		text-decoration: none;

		@include mediaMedium() {
			font-size: 14px;
		}

		&:hover {
			color: var(--c-contrast);
		}

		li:has(.header__menu-dropdown) & {
			padding-right: 22px;
			position: relative;

			&::after {
				width: 5px;
				height: 5px;
				display: block;
				content: '';
				top: 50%;
				position: absolute;
				right: 8px;
				margin-top: -4px;
				transform: rotate(-45deg);
				transition: transform 0.3s;
				border-bottom: 1.8px solid;
				border-left: 1.8px solid;
			}


		}
	}

	// .header__menu-dropdown
	&__menu-dropdown {
		// display: none;
		position: absolute;
		left: 0;
		border-radius: 14px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 14px;
		background: #fff;
		top: 100%;
		opacity: 0;
		transform: translateY(-25px);
		transition: all 0.3s;
		pointer-events: none;

		li:hover & {
			pointer-events: initial;
			transform: rotateX(0deg);
			opacity: 1;
			transform: translateY(0px);
		}

		a {
			font-weight: 500;
			font-size: 16px;
			line-height: 125%;
			letter-spacing: -0.01em;
			color: var(--c-text);
			white-space: nowrap;
			text-decoration: none;

			&:hover {
				color: var(--c-contrast);
			}
		}

	}

	&__phone {
		color: var(--c-text);
		text-decoration: none;
		font-size: 12px;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: -0.02em;

		&:hover {
			color: var(--c-contrast);
		}

		margin-right: -26px;

		@include mediaMedium {
			margin-right: 0;
		}

		@include mediaTablet {
			display: none;
		}
	}

	// .header__btn
	&__btn {
		background: var(--c-text);
		border-radius: 16px;
		font-weight: 600;
		font-size: 14px;
		line-height: 157%;
		letter-spacing: -0.02em;
		color: #fff;
		padding: 8px 16px;
		transition: background 0.3s;

		@include mediaMobile {
			border-radius: 12px;
			font-size: 12px;
			padding: 6px 10px;
		}

		&:hover {
			background: var(--c-contrast);
		}
	}
}