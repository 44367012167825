.top-block {
	margin-bottom: 100px;
	padding-top: 0;
	padding-bottom: 0;

	&__tags {
		display: flex;
		gap: 10px;
		flex-direction: row;
		position: relative;
		z-index: 5;

		@include mediaMobile {
			margin-top: 20px;
			align-items: flex-start;
			flex-direction: column;
		}

		li {
			border-radius: 16px;
			padding: 8px 16px;
			font-weight: 700;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.02em;
			white-space: nowrap;
			background: #fff;

			@include mediaMedium {
				font-size: 14px;
			}

			@include mediaMobile {
				font-weight: 500;
				background: var(--c-gray);
			}
		}
	}

	&__img {
		aspect-ratio: 1800/744;
		object-fit: cover;
		align-items: flex-start;
		position: relative;
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include mediaTablet {
			aspect-ratio: 708/315;
		}

		@include mediaMobile {
			padding: 0;
			aspect-ratio: initial;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include mediaMobile {
				aspect-ratio: 320/200;
				position: relative;
				height: initial;
				border-radius: 30px;
			}
		}
	}

	&__date {
		font-size: 20px;
		@include clampf(16px, 20px, 1366px, 1920px);

		@media(max-width:768px) {
			@include clampf(14px, 16px, 360px, 768px);
		}

		line-height: 140%;
		letter-spacing: -0.01em;
		opacity: 0.5;

		@include clamp("padding-bottom", 6px, 10px, 1100px, 1920px);

		@include mediaTablet() {
			margin-left: 0;
			margin-right: auto;
		}

		margin-left: auto;
	}

	&__head {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		align-items: baseline;
		gap: 10px;

		@include mediaTablet() {
			margin-bottom: 20px;
		}

		&:has(.top-block__date) {
			align-items: flex-end;

			@include mediaTablet() {
				flex-direction: column;
			}
		}

		.h3,
		.h3,
		.h1,
		.h2,
		h1,
		h2 {
			margin-right: auto;
			margin-bottom: 0;
		}
	}
}


.top-and-img {
	aspect-ratio: 1800/800;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 0 40px 40px 40px;

	@include mediaMedium {
		aspect-ratio: 1286/532;
	}

	@include mediaTablet {
		aspect-ratio: 707/594;
	}

	@include mediaMobile {
		aspect-ratio: initial;

		display: flex;
		flex-direction: column-reverse;
	}

	@include mediaMedium {
		border-radius: 0 30px 30px 30px;
	}



	// .top-and-img__img
	&__img {
		position: absolute;
		position: absolute;
		left: 0;
		top: 1px;
		width: 100%;
		height: 100%;

		@include mediaMobile {
			border-radius: 30px;
			position: relative;
			top: 0;
			aspect-ratio: 320/263;
			overflow: hidden;
		}

		img,
		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	// .top-and-img__content
	&__content {

		position: relative;
		z-index: 3;
		// min-width: max(50.4%, 540px);

		@include mediaMobile() {
			min-width: auto;

			min-width: 100%;
		}

		.headline {

			margin-bottom: 0;

		}


	}

	&__content-inner {
		transition: transform 0.8s;
		transform: translateY(-100%);
		padding-right: 80px;
		background: #fff;
		padding-bottom: 80px;
		border-radius: 0 0 40px 0;
		position: relative;
		padding-top: 1px;
		margin-top: -1px;
		padding-top: 9px;

		body:has(#params[data-bg="gray"]) & {
			background: var(--c-gray);
		}

		@include mediaMedium {
			padding-top: 4px;
			padding-bottom: 40px;
		}

		@include mediaTablet {

			padding-bottom: 33px;
		}

		@include mediaMobile {
			padding-right: 37px;
			padding-bottom: 37px;
			border-radius: 0 0 20px 0;
			border-radius: 0;
			padding: 0;
			margin-bottom: 25px;
		}



		._is-animated & {
			transform: translateY(-0%);
		}
	}

	&__corner-1,
	&__corner-2 {
		position: absolute;
		width: 42px;
		height: 42px;
		color: #fff;

		body:has(#params[data-bg="gray"]) & {
			color: var(--c-gray);
		}

		@include mediaMedium {
			width: 30px;
			height: 30px;
		}


	}

	&__corner-1 {
		left: calc(100% - 1px);
		transition: transform 0.8s;
		transform: translateY(-39px);


		._is-animated & {
			transform: translateX(0px) translateY(0px);
		}
	}

	&__corner-2 {
		top: calc(100% - 1px);
		left: -1px;
	}
}

.info-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-top: 20px;
	gap: 16px;

	@include mediaMedium {
		gap: 10px;
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}

	&__item {
		padding: 20px;
		display: flex;
		flex-direction: column;

		min-height: 356px;
		background: var(--c-gray);

		body:has(#params[data-bg="gray"]) & {
			background: #fff;
		}

		@include mediaMedium {
			min-height: 206px;
		}

		@include mediaTablet {
			min-height: 204px;
		}

		@include mediaMobile {}
	}

	&__icon {
		width: 40px;
		height: 40px;
		color: var(--c-contrast);
		margin-bottom: auto;
	}

	&__title {
		margin-bottom: 10px;


		@include mediaMobile {
			font-size: 40px;
		}

		@include mediaMedium {
			margin-bottom: 0;
		}
	}
}