.photo-slider {
	padding: 0;




	&__next,
	&__prev {
		position: relative;
		z-index: 10;

		@include mediaMobile {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__next {
		@include mediaMobile {
			right: 20px;
		}
	}

	&__prev {
		@include mediaMobile {
			left: 20px;
		}
	}

	&__main {
		@include mediaMobile {
			display: none;
		}

		.swiper-slide {
			// aspect-ratio: 1920/1119;
			// @include mediaMedium() {
			// 	aspect-ratio: 1366/752;
			// }

			// @include mediaTablet() {
			// 	aspect-ratio: 768/1125;
			// }
			min-height: 100svh;
			position: relative;

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::after {
				display: block;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
				height: 40%;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				content: '';
			}
		}
	}

	&__thumb-wrap {
		position: absolute;
		left: 0;
		bottom: 80px;
		width: 100%;
		display: flex;
		flex-direction: row;
		z-index: 10;
		align-items: center;

		@include mediaMobile {
			position: relative;
			left: 0;
			padding: 0;
			bottom: 0;
		}
	}

	&__thumb {
		min-width: 0;
		flex: 1;
		max-width: min(1500px, calc(100% - 140px));
		margin-left: auto;
		margin-right: auto;

		@include mediaMobile {
			overflow: visible;
			max-width: calc(100% - 100px);
		}

		.swiper-slide {
			aspect-ratio: 287/190;
			position: relative;
			border-radius: 20px;
			overflow: hidden;
			filter: grayscale(1);
			transition: filter 0.3s;

			@include mediaMobile {
				transition: transform 0.3s;
				transform: scale(0.9);

				&.swiper-slide-active {
					transform: scale(1);
					filter: grayscale(0);
				}
			}

			&.swiper-slide-active {}

			&.swiper-slide-thumb-active {
				border: 1px solid var(--c-contrast);
				filter: grayscale(0);

				@include mediaMobile {
					border: 0 solid;
				}
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@include mediaMedium() {
				aspect-ratio: 206/136;
			}

			@include mediaTablet() {
				aspect-ratio: 163/107;

			}

			@include mediaMobile() {
				aspect-ratio: 258/388;

			}
		}
	}
}

.block.photo-slider {
	@include mediaMobile {
		margin-bottom: 50px;
	}
}