.entertainment-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 40px 16px;

	@include mediaMedium {
		gap: 40px 10px;
	}

	@include mediaTablet {
		gap: 40px 12px;
	}

	@include mediaMobile {
		gap: 40px 8px;
		grid-template-columns: 1fr 1fr;
	}
}