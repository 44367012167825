.rules-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 16px;

	@include mediaMedium {
		gap: 10px
	}

	.rule {
		max-width: 100%;
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}

}

.rule {
	background: #fff;

	&__icon {
		width: 34px;
		height: 34px;
		color: var(--c-contrast);
		margin-bottom: 50px;
	}

	&__title {
		margin-top: auto;
	}

	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: auto;

	padding: 20px;

	@media(max-width:930px) {
		max-width: 258px;
	}

	@include mediaTablet {
		min-height: 210px;
	}

	@include mediaMobile {
		max-width: 100%;
	}
}