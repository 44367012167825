.text {
	&__row {
		max-width: 66.34%;


		.text:has(.text__aside) & {
			@include clamp("max-width", 1070px, 1495px, 1366px, 1920px);

			@media(max-width:1366px) {
				max-width: 100%;
			}
		}

		display: flex;
		flex-direction: row;
		margin: 0 auto;
		justify-content: space-between;

		@include mediaTablet {
			max-width: 100%
		}

		margin-bottom: 80px;

		@include mediaTablet {
			margin-bottom: 60px;
		}

		@include mediaMobile {
			margin-bottom: 40px;
		}

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	&__content {
		max-width: 100%;

		div:has(.aside-block),
		.aside-block {
			display: none;

			@include mediaTablet {
				display: block;
			}
		}

		.text:has(.text__aside) & {
			@include clamp("max-width", 746px, 1044px, 1366px, 1920px);

			@include mediaTablet {
				max-width: 100%;
			}
		}

		@include mediaTablet {
			max-width: 100%
		}

		display: flex;
		flex-direction: column;
		gap: 80px;

		@include mediaTablet {
			gap: 60px;
		}

		@include mediaMobile {
			gap: 40px;
		}

		.popup & {
			gap: 40px;
		}

	}

	&__aside {
		@include clamp("max-width", 206px, 287px, 1366px, 1920px);

		@include mediaTablet {
			display: none;
		}
	}

	&__aside-sticky {
		position: sticky;
		display: flex;
		top: 10px;
		flex-direction: column;
		gap: 10px;
	}
}

.aside-block {
	background: var(--c-gray);
	padding: 30px 20px;

	[data-bg="gray"] & {
		background: #fff;
	}

	&__title {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 20px;
		line-height: 150%;
		letter-spacing: -0.02em;
		color: #6e6e71;

		svg {
			margin-top: -1px;
			width: 24px;
			height: 24px;
			color: var(--c-contrast);
		}
	}

	&__content {


		p {
			margin-bottom: 20px;
			font-weight: 700;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.02em;

			@include mediaMedium {
				font-size: 14px;
				line-height: 143%;
				letter-spacing: -0.02em;
			}

			color {
				color: var(--c-contrast);
			}

			&:last-child {
				margin-bottom: 0 !important;
			}

			a {
				text-decoration: underline;
				color: var(--c-text);

				&:hover {
					text-decoration: none;
					color: var(--c-contrast);
				}
			}
		}
	}
}

.text-aside {

	flex-direction: column;
	gap: 10px;
	display: none;

	@include mediaTablet {
		display: flex;
	}
}


.alert {
	padding: 50px 40px;
	color: #df5e4c;

	p {
		font-weight: 600;

		@include mediaMobile {
			line-height: 150%;
			letter-spacing: -0.01em;
			font-size: 16px;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	background: rgba(223, 93, 76, 0.07);
}



.table-price {
	padding: 25px 20px;


	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}

	td:nth-child(2) {
		white-space: nowrap;
	}

	tr {
		td {
			font-size: 16px;

			@include mediaMedium {
				font-size: 14px;
			}
		}

		h4,
		.h4 {
			margin-top: 40px !important;
			margin-bottom: 10px;

			.popup & {
				font-size: 20px;
				position: relative;

				@include mediaTablet() {
					font-size: 16px;
				}

				&::after {
					display: block;
					content: '';
					background: #CED3DF;
					width: 100%;
					height: 1px;
					position: absolute;
					left: 0;
					top: -20px;
				}
			}
		}
	}

	tr:first-child {

		h4,
		.h4 {
			margin-top: 0 !important;

			&::after {
				display: none !important;
			}
		}
	}

	@include mediaTablet {
		padding: 28px 20px;
	}

	background: var(--c-gray);



}

.tabs-slider {
	&__tabs {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		max-width: 100vw;
		margin-left: calc(-1 * var(--inner-padding));
		margin-right: calc(-1 * var(--inner-padding));
		padding-left: var(--inner-padding);
		padding-right: var(--inner-padding);
		overflow: scroll;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
			opacity: 0;
		}
	}

	&__tab {
		padding: 8px 16px;
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		color: #6e6e71;
		transition: background 0.4s, color 0.4s;
		border-radius: var(--border-radius);
		white-space: nowrap;

		&::after {
			padding-left: 0;
			display: none;
		}

		&:hover {
			color: #000;
		}

		&._active {
			background: var(--c-text) !important;
			color: #fff !important;
		}
	}
}

.photo-slider {
	.btn-arrow {
		min-width: initial;
	}

	.h3,
	h3 {
		@media(min-width:1400px) {
			font-size: 32px;
		}
	}

	&:has(.photo-slider__slide-content) {
		.photo-slider__nav {
			display: none;
		}

		.photo-slider__footer {
			padding: 40px;
			right: 0;
			left: initial;
			width: initial;

			@include mediaTablet {
				padding: 20px 30px;
			}
		}
	}

	&__slide {
		position: relative;
		overflow: hidden;
		border-radius: var(--border-radius);

		.photo-slider:has(.photo-slider__slide-content) & {

			img {
				aspect-ratio: 1800/744;
				width: 100%;

				@include mediaMedium {
					aspect-ratio: initial;
					min-height: 532px;
					max-height: 532px;

				}

				@include mediaTablet {
					min-height: 400px;
					max-height: 400px;

				}

				@include mediaMobile {
					min-height: 652px;
					max-height: 652px;

				}
			}

		}

		img {
			aspect-ratio: 1044/588;
			object-fit: cover;

			@include mediaMedium {
				aspect-ratio: 1286/532;
			}

			@include mediaTablet {
				aspect-ratio: 708/400;
			}

			@include mediaMobile {
				aspect-ratio: initial;
				aspect-ratio: 1/1;
			}
		}

		.text {


			img {
				aspect-ratio: 1044/588;
				object-fit: cover;

				@include mediaMobile {
					aspect-ratio: 1/1;
				}
			}
		}

		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 50%;
			display: block;
			content: '';
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			border-radius: 0 0 var(--border-radius) var(--border-radius);
			z-index: 1;
		}
	}

	&__slide-content {
		position: absolute;
		left: 0;
		top: 0;
		width: calc(100% - 100px);
		color: #fff;


		height: 100%;
		z-index: 21;
		max-width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		padding: 40px;

		@include mediaMobile() {
			width: 100%;
		}

		@include mediaTablet() {

			padding: 20px;
		}
	}

	position: relative;

	&__footer {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 30px 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 11;
		width: 100%;

		@include mediaTablet {
			padding: 20px 30px;
		}
	}

	&__pagi {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.01em;
		color: #fff;
		margin-right: auto;
		position: absolute;
		left: 40px;
		top: 40px;

		.photo-slider__footer & {
			position: relative;
			left: 0;
			top: 0;
		}
	}

	&__nav {
		display: flex;
		flex-direction: row;
		gap: 6px;
		margin-left: auto;

		.photo-slider__prev,
		.photo-slider__next {
			position: relative;
			transform: none;
			left: 0;
			top: 0;
			right: 0;
		}
	}




}

.blockquote {
	padding: 50px 40px;
	background: rgba(223, 93, 76, 0.07);

	&[data-bg="white"] {
		background: #fff;
	}

	@include mediaTablet {
		padding: 40px;
	}

	@include mediaMobile {
		padding: 50px 20px;
	}

	&__content {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='47' height='39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.559 1 9.733 38H1.236L9.13 1h14.429ZM45.538 1 31.16 38h-8.914l8.21-37h15.082Z' stroke='%23DF5E4C' stroke-width='2'/%3E%3C/svg%3E");
			display: block;
			content: '';
			width: 47px;
			height: 39px;
			margin-bottom: 30px;
		}
	}

	&__person-name {
		margin-top: 30px;
		font-weight: 600;
		@include clampf(16px, 20px, 1366px, 1920px);

		@include mediaMedium {
			line-height: 150%;
			letter-spacing: -0.01em;
		}

		@media(max-width:768px) {
			@include clampf(14px, 16px, 320px, 768px);
		}

		line-height: 140%;
		letter-spacing: -0.01em;
	}

	&__person-position {
		font-weight: 300;
		@include clampf(16px, 20px, 1366px, 1920px);

		@include mediaMedium {
			line-height: 150%;
			letter-spacing: -0.01em;
		}

		@media(max-width:768px) {
			@include clampf(14px, 16px, 320px, 768px);
		}

		line-height: 140%;
		letter-spacing: -0.01em;
	}

}

.grid-2 {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr;

	.typography {
		max-width: 757px;
	}

	@include mediaTablet() {
		grid-template-columns: 1fr;
	}

	&__img-wrap {
		overflow: hidden;
		position: relative;

		&::before {
			display: block;
			content: '';
			aspect-ratio: 892/585;

			@include mediaTablet() {
				aspect-ratio: initial;
				height: 320px;
			}
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}