.number-detail {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 20px;

	@include mediaMedium() {
		gap: 10px;
	}

	@include mediaTablet() {
		grid-template-columns: 1fr;
	}

	&__1of1,
	&__1of3 {
		padding: 30px;
		background: var(--c-gray);
		position: relative;

		[data-bg="gray"] & {
			background: #fff;
		}
	}

	.typography {
		max-width: initial;
		position: relative;
		z-index: 5;
	}

	&__1of1 {
		max-width: initial;
		grid-column: span 3;

		@include mediaTablet() {
			grid-column: span 1;
		}

	}

	&__1of3,
	&__1of3-bg {
		min-height: 420px;

		@include mediaMedium() {
			min-height: 500px;
		}

		@include mediaTablet() {
			min-height: 348px;
		}

		@include mediaMobile() {
			min-height: 500px;
		}

	}

	&__1of3-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__1of3-particle {
		max-height: 213px;
		position: absolute;
		right: 30px;
		bottom: 30px;
	}
}