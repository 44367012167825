.spec-preview {
	display: flex;
	flex-direction: column;
	position: relative;

	padding: 20px;
	align-items: flex-start;
	overflow: hidden;
	min-height: 580px;

	.spec-grid {

		min-height: 500px;


		@include mediaMobile {
			border-radius: 20px;
		}

		@include mediaTablet {
			min-height: 400px;
		}
	}

	@include mediaMedium {
		min-height: 500px;
	}

	@include mediaMobile {
		border-radius: 20px;
	}

	@include mediaTablet {
		min-height: 400px;
	}

	// @include mediaMobile {
	// 	min-height: initial;
	// 	padding: 0;
	// 	border-radius: 0px;
	// }

	&__tag {
		background: #fff;
		font-weight: 400;
		font-size: 14px;
		line-height: 157%;
		border-radius: 30px;
		letter-spacing: -0.0em;
		padding: 8px 16px;
		position: relative;
		z-index: 2;
		font-family: var(--font-family);

		// @include mediaMobile {
		// 	display: none;
		// }
	}

	&__img-wrap {
		line-height: 0;
		z-index: 1;
		margin-bottom: 20px;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;


		// @include mediaMobile {
		// 	aspect-ratio: 1/1;
		// 	position: relative;
		// 	margin-bottom: 20px;
		// 	width: 100%;
		// 	height: initial;
		// 	border-radius: 20px;
		// }

		img {

			object-fit: cover;
			transition: transform 0.5s;
			position: absolute;

			left: 0;
			top: 0;
			width: 100%;
			height: 100%;


			.spec-preview:hover & {
				transform: scale(1.01);
			}
		}

		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 70%;
			display: block;
			content: '';

			// @include mediaMobile {
			// 	display: none;
			// }

			background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(28, 28, 28, 0) 100%);
		}
	}

	&__title {
		text-decoration: none;

		display: block;
		margin-top: auto;
		z-index: 2;
		position: relative;
		color: #fff;
		margin-bottom: 13px;

		// @include mediaMobile {
		// 	color: var(--c-text)
		// }
	}

	&__link {
		color: #fff;

		// @include mediaMobile {
		// 	color: var(--c-text);
		// 	border-color: var(--c-border);
		// }

		;
		border-radius: 16px;
		padding: 10px 16px;
		border: 1px solid currentColor;
		display: flex;
		z-index: 2;
		flex-direction: row;
		align-items: center;
		width: 100%;
		transition: color 0.5s, background 0.5s;
		text-decoration: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;


		@include mediaMedium {
			font-size: 14px;
			line-height: 143%;
			letter-spacing: -0.01em;
		}

		// @include mediaMobile {
		// 	width: 100%;
		// }

		&:hover {
			background: #fff;
			color: var(--c-text);

			svg {
				transform: rotate(45deg);
			}
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}

		svg {
			margin-left: auto;
			max-width: 22px;
			max-height: 22px;
			transition: transform 0.5s;
		}
	}

}