.restaurant-preview {
	position: relative;

	overflow: hidden;



	&__link {
		color: var(--c-text);
		text-decoration: none;

		[data-bg="blue"] & {
			color: #fff;

			&:hover {
				color: var(--c-contrast);
			}
		}

		&:hover {
			color: var(--c-contrast);
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			content: '';
			width: 100%;
			height: 100%;

		}
	}

	&__img-wrap {
		position: relative;
		overflow: hidden;
		aspect-ratio: 589/422;
		margin-bottom: 20px;

		@include mediaMedium {
			aspect-ratio: 638/422;
		}

		@include mediaTablet {
			aspect-ratio: 348/230;
		}

		@include mediaMobile {
			aspect-ratio: 320/240;
		}

		.restaurant-slider & {
			@include mediaMedium {
				aspect-ratio: 638/422;
			}

			@include mediaTablet {
				aspect-ratio: 348/230;
			}

			@include mediaMobile {
				aspect-ratio: 320/240;
			}
		}

		img {
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: transform 0.5s;

			.restaurant-preview:hover & {
				transform: scale(1.01);
			}
		}
	}

}

.restaurant-slider__swiper {
	overflow: visible !important;
}