.footer {
	position: relative;
	z-index: 88;

	// padding: 0 0 60px 0;
	.form1+& {
		margin-top: 110px;
	}

	// @include clamp('padding-top', 40px, 60px, 1366px, 1920px);
	@include clamp('padding-bottom', 40px, 60px, 1366px, 1920px);

	@media(max-width:1367px) {
		// @include clamp('padding-top', 30px, 40px, 768px, 1366px);
		@include clamp('padding-bottom', 30px, 40px, 768px, 1366px);
	}

	@media(max-width:769px) {
		// @include clamp('padding-top', 20px, 30px, 360px, 768px);
		@include clamp('padding-bottom', 20px, 30px, 360px, 768px);
	}

	.block-wrapper:has(.block[data-bg="gray"])+&,
	.block[data-bg="gray"]+& {
		background: var(--c-gray);
	}

	.block-wrapper:has(.block[data-bg="white"])+&,
	.block[data-bg="white"]+& {
		background: #fff;

		.footer__block {
			background: var(--c-gray);
		}
	}

	// .footer__block
	&__block {
		padding: 30px;
		border-radius: var(--border-radius);
		background: #fff;
		display: flex;
		flex-direction: column;

		.block[data-bg="gray"]+& {
			background: var(--c-gray);
		}

		@include mediaTablet {
			padding: 30px 20px;
		}
	}

	// .footer__line-1
	&__line-1 {
		display: flex;
		margin-bottom: 20px;
		gap: 20px 50px;

		justify-content: space-between;

		@include mediaTablet {
			margin-bottom: 22px;
		}

		@include mediaMobile {
			flex-direction: column;
		}
	}

	// .footer__phone-item
	&__phone-item {
		&:nth-child(even) {
			text-align: right;

			@include mediaMobile {
				text-align: left;
			}
		}

		a {
			font-family: var(--font-family);
			font-weight: 400;
			font-size: 48px;
			line-height: 112%;
			color: var(--c-text);
			letter-spacing: 0.01em;
			text-decoration: none;

			@include mediaTablet {
				font-size: 24px;
			}

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	// .footer__phone-desc
	&__phone-desc {
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		margin-bottom: 2px;
		letter-spacing: -0.01em;
	}

	// .footer__line-2
	&__line-2 {
		display: flex;
		margin-bottom: 74px;
		gap: 20px 50px;
		justify-content: space-between;
		align-items: center;

		@include mediaMedium {
			margin-bottom: 52px;
			align-items: flex-start;
		}

		@include mediaTablet {
			margin-bottom: 36px;
		}

		@include mediaMobile {
			flex-direction: column;
			margin-bottom: 17px;
		}
	}

	// .footer__adress
	&__adress {


		p {
			max-width: 337px;
			font-weight: 400;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.01em;

			@include mediaTablet {
				max-width: 300px;
			}
		}
	}

	// .footer__socials
	&__socials {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 13px;

		@include mediaMobile {
			justify-content: flex-start;
		}

		li {
			a {
				font-size: 0;
				line-height: 0;
				color: #202C41;


				svg {
					width: 32px;
				}

				&.footer__socials-yt {
					&:hover {
						color: #c4302b;
					}
				}

				&.footer__socials-vk {
					&:hover {
						color: #0077ff;
					}
				}

				&.footer__socials-ok {
					&:hover {
						color: #ed812b;
					}
				}
			}
		}
	}

	// .footer__ya-widget
	&__ya-widget {
		margin-top: 13px;

		@include mediaMedium {
			margin-top: 34px;
		}

		@include mediaTablet {
			margin-top: 18px
		}
	}

	// .footer__line-3
	&__line-3 {
		display: flex;
		margin-bottom: 20px;
		gap: 20px 50px;
		justify-content: space-between;
		align-items: center;

		@include mediaMobile {
			order: -1;
			margin-bottom: 30px;
		}
	}

	// .footer__logo
	&__logo {
		line-height: 0;

		img {
			width: 152px;
		}
	}

	// .footer__menu
	&__menu {
		display: flex;
		flex-direction: row;
		gap: 10px 20px;
		width: 500px;
		flex-wrap: wrap;
		max-width: 100%;
		justify-content: flex-end;

		@include mediaTablet {
			display: none;
		}

		a {
			font-weight: 400;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.01em;
			text-decoration: none;
			color: var(--c-text);

			&:hover {
				color: var(--c-contrast)
			}

			;
		}
	}

	// .footer__bottom
	&__bottom {
		border-top: 1px solid #ced3df;
		display: grid;
		padding-top: 20px;
		grid-template-columns: 509px 1fr 1fr;

		@include mediaTablet {
			padding-top: 10px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: "col1 col3"
				"col2 col3";
			gap: 8px 20px;
			align-items: center;
		}

		@include mediaMobile {
			grid-template-columns: 1fr;
			grid-template-areas: "col1 "
				"col2 "
				"col3 ";
		}
	}

	// .footer__col-1
	&__col-1 {
		@include mediaTablet {
			grid-area: col1;
		}
	}

	// .footer__copy
	&__copy {
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.01em;
	}

	// .footer__col-2
	&__col-2 {
		@include mediaTablet {
			grid-area: col2;
		}
	}

	// .footer__politic
	&__politic {
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		color: var(--c-text);
		letter-spacing: -0.01em;
		text-decoration: none;

		&:hover {
			color: var(--c-contrast);
		}
	}

	// .footer__col-3
	&__col-3 {
		text-align: right;

		@include mediaTablet {
			grid-area: col3;
		}

		@include mediaMobile {
			padding-top: 20px;
			border-top: 1px solid #ced3df;
		}
	}

	//.footer__ctm
	&__ctm {
		line-height: 0;

		img {
			width: 120px;
		}
	}
}