.news-preview {
	background: #fff;
	padding: 20px;
	display: flex;
	flex-direction: column;
	position: relative;

	min-height: 440px;

	@include mediaMedium {
		min-height: 424px;
	}

	@include mediaTablet {
		min-height: 336px;
	}

	@include mediaMobile {
		min-height: 309px;
	}

	&__row {
		display: grid;
		grid-template-columns: auto 160px;

		@include mediaTablet() {
			grid-template-columns: auto 98px;
		}

		margin-bottom: auto;

	}



	&__link {
		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	&__title {
		text-decoration: none;
		color: var(--c-text);
		display: block;
		margin-bottom: 16px;

		@include mediaTablet {
			font-size: 16px;
			margin-bottom: 20px;
		}

	}

	&__img-wrap {
		border-radius: 20px;
		aspect-ratio: 160/182;
		position: relative;
		overflow: hidden;
	}

	&__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transition: transform 0.5s;
		object-fit: cover;

		.news-preview:hover & {
			transform: scale(1.06);
		}
	}

	&__left {
		display: flex;
		font-weight: 500;
		font-size: 14px;
		opacity: 0.5;
		line-height: 157%;
		letter-spacing: -0.02em;
		flex-direction: column;
	}
}

.news-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 16px;

	@include mediaMedium() {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 10px;
	}

	@include mediaTablet() {
		grid-template-columns: 1fr 1fr;
		gap: 12px;
	}

	@include mediaMobile() {
		grid-template-columns: 1fr;
		gap: 8px;
	}
}