.vacancy-preview {
	background: var(--c-gray);
	margin-bottom: 20px;
	padding: 40px 57px;

	h4,
	.h4 {
		@include mediaTablet {
			font-size: 14px;
			margin-bottom: 10px !important;
		}
	}

	h3,
	.h3 {
		@include mediaTablet {
			font-size: 16px;
			font-weight: 600;
		}

		@include mediaMobile {
			font-size: 14px;
		}
	}

	@include mediaMedium {
		padding: 40px;
	}

	@include mediaTablet {
		padding: 30px 20px;
	}

	@include mediaMobile {
		padding: 20px;
	}

	.block[data-bg="gray"] & {
		background: #fff;

	}

	&__top {
		position: relative;
		padding-right: 100px;
		cursor: pointer;

		@include mediaMobile {
			padding-right: 0;
		}

	}

	&__toggle {
		position: absolute;
		width: 20px;
		height: 20px;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;

		@include mediaMobile {
			transform: none;
			top: 0;
		}

		&::after {
			width: 1px;
			height: 100%;
			left: 50%;

			.vacancy-preview._open & {
				display: none;
			}

			top: 0;
		}

		&::before {
			height: 1px;
			width: 100%;
			top: 50%;
			left: 0;


		}

		&::after,
		&::before {
			position: absolute;
			background: var(--c-text);
			display: block;
			content: '';
		}
	}

	&__body {
		display: none;
		padding-top: 1px;
	}

	h3 {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px 50px;
		max-width: 1360px;

		@include mediaTablet() {
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	&__footer {
		display: flex;
		flex-direction: row;
		font-weight: 600;
		font-size: 24px;
		line-height: 133%;
		letter-spacing: -0.02em;
		padding: 0;
		list-style: none;
		margin: 40px 0;

		@include mediaMedium {
			font-size: 20px;
		}

		@include mediaTablet {
			gap: 15px;
			font-size: 16px;
			flex-direction: column;
		}

		@include mediaMobile {
			font-size: 14px;

		}

		li {
			&::after {
				display: inline-block;
				content: '|';
				margin-left: 15px;
				margin-right: 15px;

				@include mediaTablet {
					display: none;
				}
			}

			a {
				color: var(--c-contrast);
				text-decoration: none;
			}

			&:last-child::after {
				display: none;
			}
		}
	}

	&__hh {
		img {
			width: 34px;
			height: 34px;
			margin: -10px 0;
			margin-left: 15px;
		}
	}

	&__tags {
		display: flex;
		flex-direction: row;
		opacity: 0.5;
		padding: 0;
		list-style: none;

		@include mediaMobile {
			flex-direction: column;
			gap: 10px;
			font-size: 12px;
			padding-right: 50px;
			margin-bottom: 10px;
		}

		li {
			&::after {
				display: inline-block;
				content: '|';
				margin-left: 10px;
				margin-right: 10px;

				@include mediaMobile {
					display: none;
				}
			}

			&:last-child::after {
				display: none;
			}
		}
	}

	&__body {
		margin-top: 40px;

		@include mediaTablet {
			margin-top: 20px;
		}

		@include mediaMobile {
			margin-top: 28px;
		}
	}
}