.traveline {
	position: relative;



	&__sbmt {
		color: #fff;
		transition: color 0.3s, background 0.3s;
		background: var(--c-contrast);
		padding: 12px 20px;
		border-radius: 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;

		svg {
			width: 22px;
			height: 22px;
		}
	}

	&__column {
		position: relative;
		flex: 1;
	}

	&__input-chevron {
		position: absolute;
		right: 16px;
		top: 50%;
		width: 22px;
		height: 22px;
		margin-top: -11px;
		pointer-events: none;

		svg {
			width: 100%;
			height: 100%;
		}

		transition: transform 0.3s;

		._open & {
			transform: rotate(-180deg);
		}
	}

	&__input {
		background: #fff;
		padding: 13px 20px;
		border-radius: 16px;
		width: 100%;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		border: 0 solid;
		outline: none !important;

		&::placeholder {
			color: var(--c-text);
		}
	}

	.select2-container {
		width: 100% !important;
	}

	.select2-container .select2-selection--single .select2-selection__rendered {
		padding: 0;
		line-height: 150%;
	}

	.select2-selection__arrow {
		width: 22px !important;
		height: 22px !important;
		position: absolute;
		right: 16px !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
		background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L11 14L15 10' stroke='%23202C41' stroke-width='1.6' stroke-linecap='square'/%3E%3C/svg%3E%0A");
		transition: transform 0.4s;

		b {
			display: none
		}


	}

	.select2-container--open .select2-selection__arrow {
		transform: translateY(-50%) rotate(-180deg) !important;
	}

	.select2-container--default .select2-selection--single {
		background: #fff;
		padding: 13px 20px;
		border-radius: 16px !important;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		border: 0 solid;
		height: initial;
	}


	&__img-wrap {
		position: relative;
		aspect-ratio: 1800/744;
		overflow: hidden;

		@include mediaTablet {
			aspect-ratio: initial;
			min-height: 574px;
		}

		@include mediaMobile {
			aspect-ratio: 320/200;
			min-height: initial;
			margin-bottom: 10px;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			content: "";
			width: 100%;
			height: 230px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
		}
	}

	&__form {
		max-width: min(1032px, calc(100% - 40px));
		gap: 4px;
		position: absolute;
		bottom: 40px;
		left: 50%;
		width: 100%;
		display: flex;
		flex-direction: row;
		transform: translateX(-50%);

		@include mediaMobile {
			position: relative;
			top: 0;
			bottom: 0;
			max-width: 100%;
		}

		@include mediaTablet {
			display: grid;
			gap: 12px;
			grid-template-columns: 1fr 1fr;
		}

		@include mediaMobile {
			display: grid;
			gap: 4px;
			grid-template-columns: 1fr;
		}
	}

	&__guest {
		position: relative;
	}

	&__adults {
		display: flex;
		flex-direction: row;
	}

	&__adults-counter {
		display: flex;
		gap: 10px;
		flex-direction: row;
		margin-left: auto;
	}

	&__adults-count {
		font-weight: 500;
		font-size: 14px;
		line-height: 114%;
		letter-spacing: -0.01em;
	}

	&__adults-minus,
	&__adults-plus {
		font-size: 16px;
		cursor: pointer;
		width: 15px;
		height: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		&:hover {
			color: var(--c-contrast);
		}
	}

	&__childs {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 10px;
	}


	&__child {
		display: flex;
		flex-direction: row;
		gap: 10px;
		width: 100%;
		align-items: center;
	}

	&__child-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
	}

	&__child-delete {
		margin-left: auto;
		color: #828D9E;
		cursor: pointer;

		&:hover {
			color: red;
		}

		svg {
			width: 22px;
			pointer-events: none;
			touch-action: none;
			height: 22px;
		}
	}

	&__child-drop {
		border: 1px solid var(--c-border);
		border-radius: 16px;
		padding: 10px 16px;
		margin-top: 10px;
	}

	&__child-drop-title {
		.traveline__child-drop._open & {
			display: none;
		}
	}

	&__child-drop-title,
	&__child-drop-title2 {
		font-size: 14px;
	}

	&__child-drop-title2 {
		display: none;
		color: #828d9e;

		.traveline__child-drop._open & {
			display: block;
		}
	}

	&__child-drop-list {
		margin-top: 10px;
		max-height: 140px;
		overflow-x: hidden;
		overflow-y: auto;
		display: none;
	}

	&__child-drop-add {
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;


		&:hover {
			color: var(--c-contrast);
		}

		svg {
			margin-left: auto;
		}
	}

	&__child-drop-head {
		padding-right: 30px;
		position: relative;
		width: 100%;
		cursor: pointer;

		svg {
			width: 22px;
			height: 22px;
			right: 0px;
			top: 50%;
			position: absolute;
			margin-top: -11px;
			transition: transform 0.3s;

			.traveline__child-drop._open & {
				transform: rotate(-180deg);
			}
		}
	}

	&__guest-drop {
		color: var(--c-text);
		border-radius: 16px;
		position: absolute;
		width: 100%;
		background: #fff;
		padding: 16px;
		top: calc(100% + 4px);
		display: none;

		.traveline__guest._open & {
			display: block;
		}
	}

}

.daterangepicker {
	padding: 4px 5px !important;
	border-radius: 16px !important;
	margin-top: 4px !important;
	padding: 0 !important;
	border: 0 solid !important;

	body:has(.traveline__input-date._single) & {
		width: var(--calendar-width);

		.drp-calendar.left {
			width: 100%;
			box-sizing: border-box;
		}

		.drp-calendar.right {
			display: none !important;
		}

		.drp-calendar {
			max-width: 100% !important;
		}

		table {
			max-width: 100% !important;
			width: 100% !important;
		}
	}

	table {

		width: 270px !important;
		padding: 0px !important;
		border-spacing: 0px 7px !important;
		border-collapse: separate !important;
	}

	&::before,
	&::after {
		display: none !important;
	}

	.drp-calendar.right,
	.drp-calendar.left {
		padding: 16px 20px !important;
		box-sizing: content-box;
	}

	.calendar-table td {
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		text-align: center;
		color: #0f2552;
		height: 24px;
	}

	.drp-calendar {
		max-width: initial;
	}

	.calendar-table thead {
		tr:first-child {
			th {
				border-radius: 0 !important;
				color: var(--c-text);
				font-weight: 500;
				font-size: 14px;
				text-transform: none;
				line-height: 143%;
				letter-spacing: -0.01em;
				padding-bottom: 10px;
				border-bottom: 1px solid #828d9e;
			}
		}
	}

	.drp-calendar.left.single {
		width: 100%;
		box-sizing: border-box;
	}

	.calendar-table th {
		font-weight: 400;
		font-size: 12px;
		line-height: 167%;
		letter-spacing: -0.01em;
		text-transform: uppercase;
		text-align: center;
		color: #ced3df;
	}

	td.in-range.in-range {
		color: #fff;
		background: var(--c-contrast);

		+td.available:hover {
			border-radius: 0 40px 40px 0;
		}

		&.end-date+td.available:hover {
			border-radius: 4px;
		}
	}

	.active.start-date.available.in-range {
		pointer-events: none;
	}

	tbody tr {
		display: none;
	}

	td.available:hover {
		color: #fff !important;
		background: var(--c-contrast) !important;
	}

	tbody tr:has(td:not(.off)) {
		display: table-row;
	}

	td.off {
		opacity: 0 !important;
	}

	.start-date {
		border-radius: 40px 0 0 40px !important;
	}

	.end-date {
		border-radius: 0 40px 40px 0 !important;
	}

	td.active.active,
	td.active.active:hover {
		color: #fff;
		background: var(--c-contrast);
	}
}