.number-slider {
	overflow: hidden;

	@include mediaMobile() {
		&__swiper {
			overflow: visible !important;
		}
	}
}

.number-slider-4 {
	overflow: hidden;

	.block__head-nav {}

	@include mediaMobile() {
		&__swiper {
			overflow: visible !important;
		}
	}
}