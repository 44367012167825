.contacts-row-map {
	display: grid;
	grid-template-columns: 1fr 2fr;
	margin-bottom: 16px;
	gap: 16px;

	@include mediaTablet {
		display: grid;
		grid-template-columns: 1fr;
	}
}

.contact-map {
	@include mediaTablet {
		aspect-ratio: 708/318;
	}

	@include mediaMobile {
		aspect-ratio: 320/200;
	}
}

.contacts-row-3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 16px;
	gap: 16px;

	@include mediaTablet {
		gap: 12px;
		grid-template-columns: 1fr 1fr;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}
}

.contacts-row-4 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-bottom: 16px;
	gap: 16px;

	@include mediaTablet {
		gap: 12px;
		grid-template-columns: 1fr 1fr;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}
}

.contact-item {
	background: #fff;
	border-radius: 30px;
	padding: 30px 20px;
	width: 100%;
	max-width: initial;

	h5 {
		margin-top: 40px;
		margin-bottom: 20px;

		&:first-child {
			margin-top: 0 !important;
		}

		@media(max-width:1366px) {
			@include clampf(18px, 20px, 768px, 1366px);
		}

		@media(max-width:768px) {
			@include clampf(14px, 18px, 360px, 768px);
		}

		@include mediaTablet {
			margin-top: 20px;
		}
	}

	.btn-botder {
		margin-top: 40px;
	}

	a[href^="mailto"],
	a[href^="tel"] {
		color: var(--c-text);
		text-decoration: none;

		&:hover {
			color: var(--c-contrast);
		}
	}
}

.route {
	padding: 30px 20px;
	background: var(--c-gray);
	margin-bottom: 16px;
}