.map {
	&__row {
		display: grid;
		grid-template-columns: 57.96% auto;
		gap: 16px;

		@include mediaTablet {
			grid-template-columns: 1fr;
			gap: 10px;
		}
	}

	&__map-resize-btn {
		background: #fff;
		position: absolute;
		top: 30px;
		right: 16px;
		width: 42px;
		height: 42px;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		display: none;

		svg {
			transition: color 0.3s, transform 0.4s;
			width: 24px;
			height: 24px;
		}

		&:hover {
			svg {
				color: var(--c-contrast);
				transform: scale(1.2);
			}
		}
	}

	&__map-legends {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@include mediaMobile {
			height: 290px !important;
			max-height: 290px !important;
		}
	}

	&__map-legend {
		display: grid;
		grid-template-columns: 22px auto;
		text-align: right;
		gap: 15px;

		span {
			font-size: 14px;
			line-height: 129%;
		}
	}

	&__map-wrap {
		position: relative;

		.map__map-icon {
			position: absolute;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&__map-icon {
		width: 22px;
		height: 22px;
		text-align: center;
		line-height: 22px;
		display: block;
		border-radius: 100%;
		color: #fff;
		background: var(--c-contrast);
		font-weight: 500;
		font-size: 10px;
		letter-spacing: -0.02em;
		text-align: center;
	}

	&__map-legends-wrap {
		padding: 10px 50px;
		background: #fff;

		@include mediaMobile() {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__map-legends {
		overflow-x: hidden;
		overflow-y: auto;
		margin: 15px 0;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		@include mediaTablet {
			max-height: 256px;
		}
	}

	&__map-legends-bottom {
		transform: scale(-1);
	}

	&__map-legends-top,
	&__map-legends-bottom {
		width: 40px;
		height: 40px;
		display: block;
		margin: 0 auto;
		color: #202C41;

		&._disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}