.special-slider {
	overflow: hidden;


	&__swiper {
		@include mediaMobile {
			overflow: visible;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
		height: 500px;
		padding: 20px;

		align-items: flex-start;

		&-tag {
			background: #fff;
			font-weight: 400;
			font-size: 14px;
			line-height: 157%;
			border-radius: 30px;
			letter-spacing: -0.02em;
			padding: 8px 16px;
			position: relative;
			z-index: 2;
		}

		&-img-wrap {
			line-height: 0;
			z-index: 1;
			margin-bottom: 20px;
			overflow: hidden;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;


			img {
				aspect-ratio: 1/1;
				object-fit: cover;
				transition: transform 0.5s;
				position: absolute;

				left: 0;
				top: 0;
				width: 100%;
				height: 100%;


				.special-slider__item:hover & {
					transform: scale(1.01);
				}
			}

			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 70%;
				display: block;
				content: '';
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(28, 28, 28, 0) 100%);
			}
		}

		&__title {
			text-decoration: none;
			color: var(--c-text);
			margin-bottom: 10px;

			display: block;


		}

		&__link {
			border-radius: 16px;
			padding: 10px 16px;
			border: 1px solid var(--c-border);
			display: flex;
			flex-direction: row;
			align-items: center;
			color: var(--c-text);
			transition: border 0.5s;
			text-decoration: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 143%;
			letter-spacing: -0.01em;

			@include mediaMedium {
				font-size: 14px;
				line-height: 143%;
				letter-spacing: -0.01em;
			}

			@include mediaMedium {}

			@include mediaMedium {}

			@include mediaMedium {}

			&:hover {
				border-color: var(--c-contrast);

				svg {
					transform: rotate(45deg);
				}
			}

			&::after {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				content: '';
			}

			svg {
				margin-left: auto;
				max-width: 22px;
				max-height: 22px;
				transition: transform 0.5s;
			}
		}
	}
}

.special-slider2 {
	.swiper-slide {
		&:nth-child(1) {
			z-index: 50;
		}

		&:nth-child(2) {
			z-index: 49;
		}

		&:nth-child(3) {
			z-index: 48;
		}

		&:nth-child(4) {
			z-index: 47;
		}

		&:nth-child(5) {
			z-index: 46;
		}

		&:nth-child(6) {
			z-index: 45;
		}

		&:nth-child(7) {
			z-index: 44;
		}

		&:nth-child(8) {
			z-index: 43;
		}

		&:nth-child(9) {
			z-index: 42;
		}

		&:nth-child(10) {
			z-index: 41;
		}

		&:nth-child(11) {
			z-index: 40;
		}

		&:nth-child(12) {
			z-index: 39
		}

		&:nth-child(13) {
			z-index: 38
		}

		&:nth-child(14) {
			z-index: 37
		}

		&:nth-child(15) {
			z-index: 36
		}

		&:nth-child(16) {
			z-index: 35
		}

		&:nth-child(17) {
			z-index: 34
		}

		&:nth-child(18) {
			z-index: 33
		}

		&:nth-child(19) {
			z-index: 32
		}

		&:nth-child(20) {
			z-index: 31
		}

		&:nth-child(21) {
			z-index: 29
		}

		&:nth-child(22) {
			z-index: 28
		}

		&:nth-child(23) {
			z-index: 27
		}

		&:nth-child(24) {
			z-index: 26
		}

		&:nth-child(25) {
			z-index: 25
		}

		&:nth-child(26) {
			z-index: 24
		}

		&:nth-child(27) {
			z-index: 23
		}

		&:nth-child(28) {
			z-index: 22
		}

		&:nth-child(29) {
			z-index: 21
		}

		&:nth-child(30) {
			z-index: 20
		}

		&:nth-child(31) {
			z-index: 19
		}

		&:nth-child(32) {
			z-index: 18
		}

		&:nth-child(33) {
			z-index: 17
		}

		&:nth-child(34) {
			z-index: 16
		}

		&:nth-child(35) {
			z-index: 15
		}

		&:nth-child(36) {
			z-index: 14
		}

		&:nth-child(37) {
			z-index: 13
		}

		&:nth-child(38) {
			z-index: 12
		}

		&:nth-child(39) {
			z-index: 11
		}

		&:nth-child(40) {
			z-index: 10
		}

		&:nth-child(41) {
			z-index: 9
		}

		&:nth-child(42) {
			z-index: 8
		}

		&:nth-child(43) {
			z-index: 7
		}

		&:nth-child(44) {
			z-index: 6
		}

		&:nth-child(45) {
			z-index: 5
		}

		&:nth-child(46) {
			z-index: 4
		}

		&:nth-child(47) {
			z-index: 3
		}

		&:nth-child(48) {
			z-index: 2
		}

		&:nth-child(49) {
			z-index: 1
		}
	}

	.block__head {
		.btn-arrow {


			@include mediaTablet {
				display: none;
			}
		}
	}

	.block__head-nav {}

	&__swiper {
		border-radius: var(--border-radius);

		@include mediaMobile {
			overflow: visible;
		}



	}

	@media(min-width:992px) {
		.spec-preview {
			position: relative;
			transition: left 0.25s, width 0.25s;
			transition-delay: 0ms;



		}

		.swiper-slide-active~.swiper-slide .spec-preview {
			left: calc(100% + 10px);
		}

		._transition .spec-preview {
			width: 100% !important;
			left: 0% !important;

			transition: left 0.25s, width 0.25s;
			transition-delay: 150ms;
		}

		.swiper-slide-active {
			.spec-preview {
				left: 0%;
				width: calc(200% + 10px);
			}
		}
	}
}