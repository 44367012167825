button {
	padding: 0;
	border: 0 solid;
	background: transparent;
	color: var(--c-text);
	cursor: pointer;
	font-family: var(--font-family), Helvetica, sans-serif;
}

a {
	color: var(--c-contrast);
	transition: color 0.3s;
}

strong {
	font-weight: 600;
}

h1,
.headline {
	font-weight: 400;
	font-size: 130px;
	line-height: 95%;
	letter-spacing: -0.05em;
	@include clampf(96px, 130px, 1366px, 1920px);

	@media(max-width:1366px) {

		@include clampf(64px, 96px, 768px, 1366px);
	}

	@include mediaTablet() {
		letter-spacing: -0.03em;
	}

	@media(max-width:768px) {
		@include clampf(40px, 64px, 360px, 768px);
	}

	a {
		text-decoration: none;
		color: var(--c-text);
	}

}

h2,
.h2 {
	line-height: 125%;
	font-weight: 400;
	letter-spacing: -0.03em;
	@include clampf(48px, 64px, 1366px, 1920px);
	margin-bottom: 30px;

	a {
		text-decoration: none;
		color: var(--c-text);
	}

	@media(max-width:1366px) {
		@include clampf(32px, 48px, 768px, 1366px);
		@include clamp('margin-bottom', 20px, 25px,
			768px,
			1366px);
		letter-spacing: 0;
	}

	@media(max-width:768px) {
		@include clampf(24px, 32px, 360px, 768px);
		@include clamp('margin-bottom', 10px, 20px,
			360px,
			768px);
		letter-spacing: -0.04em;
	}
}

h3,
.h3 {
	line-height: 120%;
	letter-spacing: -0.02em;
	font-weight: 400;

	a {
		text-decoration: none;
		color: var(--c-text);
	}

	@include clampf(32px, 40px, 1366px, 1920px);
	margin-bottom: 20px;

	@media(max-width:1366px) {
		@include clampf(28px, 32px, 768px, 1366px);
	}

	@media(max-width:768px) {
		@include clampf(24px, 28px, 360px, 768px);
	}
}

p big,
h4,
.h4 {
	font-weight: 600;
	font-size: 24px;
	line-height: 133%;
	letter-spacing: -0.02em;

	@include clampf(20px, 24px, 1366px, 1920px);
	margin-bottom: 20px;

	@media(max-width:1366px) {
		@include clampf(18px, 20px, 768px, 1366px);
		letter-spacing: -0.02em;
		line-height: 110%;
		margin-bottom: 19px;

	}

	@media(max-width:768px) {
		@include clampf(16px, 18px, 360px, 768px);
	}
}

h5,
.h5 {
	font-weight: 600;
	font-size: 20px;
	line-height: 122%;
	letter-spacing: -0.02em;
	margin-bottom: 20px;

	@include mediaTablet {
		font-size: 18px;
	}
}

h6,
.h6 {
	font-weight: 600;
	font-size: 16px;
	line-height: 122%;
	letter-spacing: -0.02em;
	margin-bottom: 20px;

	@include mediaTablet {
		font-size: 18px;
	}
}

.two-column {
	display: grid;
	grid-template-columns: 50% auto;
	gap: 100px;

	&>img {
		width: 100%;
		border-radius: var(--border-radius);
		object-fit: cover;

		@include mediaMobile {
			aspect-ratio: 1/1;
		}
	}

	@include mediaTablet() {
		grid-template-columns: 1fr;
		gap: 0
	}

	;
}

.two-column2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;

	&>img {
		width: 100%;
		border-radius: var(--border-radius);
		object-fit: cover;

		@include mediaMobile {
			aspect-ratio: 1/1;
		}
	}

	@include mediaTablet() {
		grid-template-columns: 1fr;
		gap: 0
	}

	;
}

.typography {
	@include clamp('max-width', 854px, 1195px, 1440px, 1920px);

	&>img:not([class]) {
		border-radius: var(--border-radius);
		object-fit: cover;

		@include mediaMobile {
			aspect-ratio: 1/1;
		}
	}

	&[data-color="white"] {

		color: #fff
	}

	h4,
	.h4 {
		margin-top: 60px;
		margin-bottom: 20px;

		@include mediaTablet {
			margin-top: 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	dl {
		display: grid;
		gap: 10px;
		grid-template-columns: auto auto;

		@include mediaMobile {
			gap: 20px 10px;
		}

		dd {
			font-weight: 700;
			text-align: right;
		}

		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.text-p,
	p {
		img {
			max-width: 100%;
			border-radius: var(--border-radius);

			@include mediaMobile {
				object-fit: cover;
				aspect-ratio: 1/1;
			}
		}

		font-weight: 300;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: -0.01em;
		margin-bottom: 20px;
		@include clampf(16px, 20px, 1366px, 1920px);

		@include mediaMedium {
			line-height: 150%;
			letter-spacing: -0.01em;
		}

		@media(max-width:768px) {
			@include clampf(14px, 16px, 360px, 768px);
		}

		@include mediaMobile {
			line-height: 140%;
		}

		&:last-child {
			margin-bottom: 0;
		}

	}

	blockquote {
		border-left: 2px solid var(--c-contrast);
		font-weight: 400;

		line-height: 120%;
		letter-spacing: -0.02em;
		padding-left: 20px;
		color: var(--c-contrast);
		@include clampf(32px, 40px, 1366px, 1920px);
		margin-bottom: 20px;

		@media(max-width:1366px) {
			@include clampf(28px, 32px, 768px, 1366px);
			letter-spacing: 0;
			letter-spacing: -0.02em;
			line-height: 115%;
		}

		@media(max-width:768px) {
			@include clampf(24px, 28px, 360px, 768px);
			line-height: 126%;
		}

		&:last-child {
			margin-bottom: 0;
		}

	}

	table {



		width: 100%;


		h3,
		.h3 {
			margin-bottom: 10px;
			margin-top: 30px;
		}

		td:last-child {}

		tr {
			&:first-child {

				.h3,
				h3 {
					margin-top: 0;
				}
			}
		}



		td {
			width: 1px;
			font-weight: 300;

			line-height: 150%;
			letter-spacing: -0.01em;
			padding-top: 5px;
			padding-bottom: 5px;
			@include clampf(16px, 20px, 1366px, 1920px);
			padding-left: 15px;

			@include mediaTablet {
				@include clampf(14px, 16px, 360px, 768px);
			}

			p {
				font-weight: 300;
				@include clampf(14px, 16px, 1366px, 1920px);

				@include mediaTablet {
					@include clampf(14px, 16px, 360px, 768px);
				}
			}



			&:first-child {
				padding-left: 0;
			}

			@include mediaMedium {
				padding-top: 4px;
				padding-bottom: 4px;
			}

			@include mediaMobile {
				width: initial;
			}


		}
	}

	ul {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		li {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&._column-2 {
			columns: 2;
			column-gap: 50px;
			display: block;

			@include mediaTablet() {
				columns: 1;
				column-gap: 0px;
			}

			li {
				page-break-inside: avoid;
			}

		}

		&:last-child {
			margin-bottom: 0;
		}

		li {
			position: relative;
			padding-left: 34px;
			font-weight: 300;
			line-height: 140%;
			letter-spacing: -0.01em;
			@include clampf(16px, 20px, 1366px, 1920px);

			@media(max-width:768px) {
				@include clampf(14px, 16px, 360px, 768px);
			}

			&::after {
				display: block;
				content: '';
				width: 4px;
				height: 4px;
				position: absolute;
				left: 10px;
				top: 10px;
				border-radius: 50%;
				background: currentColor;
			}
		}
	}

	ol {
		display: flex;
		flex-direction: column;
		gap: 10px;
		counter-reset: ol 0;

		&:last-child {
			margin-bottom: 0;
		}

		@include mediaMedium {
			gap: 12px;
		}

		li {
			position: relative;
			padding-left: 32px;
			font-weight: 300;
			line-height: 140%;
			letter-spacing: -0.01em;
			@include clampf(16px, 20px, 1366px, 1920px);

			@media(max-width:768px) {
				@include clampf(14px, 16px, 360px, 768px);
			}

			@include mediaMedium {
				padding-left: 26px;
			}

			counter-increment: ol;



			&::before {
				position: absolute;
				left: 9px;
				top: 0;
				display: block;
				content: counter(ol)".";

				@include mediaMedium {
					left: 5px;
				}
			}

		}
	}
}