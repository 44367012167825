.entertaiment-preview {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: auto;

	&__content {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__img-wrap {
		line-height: 0;
		margin-bottom: 20px;
		overflow: hidden;
		position: relative;

		img {
			aspect-ratio: 1/1;
			object-fit: cover;
			transition: transform 0.5s;
			border-radius: 20px;
			width: 100%;

			._showplace & {
				aspect-ratio: 435/560;

				@include mediaMedium() {
					aspect-ratio: 314/400;
				}

				@include mediaTablet() {
					aspect-ratio: 258/329;
				}

				@include mediaMobile() {
					aspect-ratio: 1/1;
				}
			}

			.entertaiment-preview:hover & {
				transform: scale(1.01);
			}
		}
	}

	&__title {
		text-decoration: none;
		color: var(--c-text);
		margin-bottom: 10px;

		display: block;


	}

	&__link {
		margin-top: auto;
		min-width: initial !important;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}
}