body {
	--font-family: "Onest", sans-serif;
	--c-text: #202c41;
	--c-contrast: #df5e4c;
	--c-gray: #f6f6f6;
	--c-gray2: #828d9e;
	--c-border: #ced3df;
	--c-blue: #42597a;
	--c-blue2: #202c41;
	--border-radius: 30px;
	color: var(--c-text);
	font-family: var(--font-family), Helvetica, sans-serif;
	background: #fff;
	padding-top: 120px;
	@include clamp('--inner-padding', 40px, 60px, 1366px, 1920px);

	@media(max-width:1367px) {
		@include clamp('--inner-padding', 30px, 40px, 768px, 1366px);
	}

	@media(max-width:769px) {
		@include clamp('--inner-padding', 20px, 30px, 360px, 768px);
	}

	&:has(.menu._open) {
		overflow: hidden;
	}


	&:has(.header._not-top) {
		padding-top: 78px;

		@include mediaMobile {
			padding-top: 66px;
		}
	}

	@include mediaMobile {
		padding-top: 114px;
	}


}

* {
	font-family: var(--font-family);
	box-sizing: border-box;
}

body {
	&:has(#params[data-bg="gray"]) {
		background: var(--c-gray);
	}
}

.slider-prev-btn,
.slider-next-btn {
	width: 42px;
	height: 42px;
	border-radius: 10px;
	background: #fff;
	position: relative;
	cursor: pointer;

	&:hover {
		color: var(--c-contrast);
	}

	&.swiper-button-disabled {
		color: #828D9E !important;
	}

	&::after {
		width: 7px;
		height: 7px;
		position: absolute;
		border-left: solid 1.8px;
		border-bottom: solid 1.8px;
		top: 50%;
		display: block;
		content: "";
	}

}

.slider-prev-btn {
	&::after {
		left: 56%;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}
}

.slider-next-btn {
	&::after {
		left: 44%;
		transform: translateX(-50%) translateY(-50%) rotate(225deg);
	}
}

img {
	max-width: 100%;
}

.border-card {
	border-radius: var(--border-radius);
	padding: 20px;
	border: 1px solid var(--c-border);
	display: flex;
	flex-direction: column;
	position: relative;

	.btn-arrow {
		margin-top: auto;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}
}

.inner {
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	@include clamp('padding-left', 40px, 60px, 1366px, 1920px);
	@include clamp('padding-right', 40px, 60px, 1366px, 1920px);

	@media(max-width:1367px) {
		@include clamp('padding-left', 30px, 40px, 768px, 1366px);
		@include clamp('padding-right', 30px, 40px, 768px, 1366px);
	}

	@media(max-width:769px) {
		@include clamp('padding-left', 20px, 30px, 360px, 768px);
		@include clamp('padding-right', 20px, 30px, 360px, 768px);
	}

	// @include mediaTablet {}
}

.br {
	border-radius: var(--border-radius);
	overflow: hidden;
}

.mw100 {
	max-width: 100% !important;
}

._large-hidden {
	@media (min-width: #{$medium-width}) {
		display: none !important;
	}
}

._medium-hidden {
	@media (min-width: #{$tablet-width}) and (max-width: #{$medium-width}) {
		display: none !important;
	}
}

._tablet-hidden {
	@media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
		display: none !important;
	}
}

._mobile-hidden {
	@include mediaMobile {
		display: none !important;
	}
}

.block {
	position: relative;
	z-index: 4;
	opacity: 0;
	margin-top: 100px;
	transition: all 0.3s;
	padding-top: 110px;
	padding-bottom: 220px;
	margin-bottom: -110px;

	@include mediaTablet() {
		overflow: hidden;

		.swiper {
			overflow: visible !important;
		}
	}

	&[data-bg="bg"],
	&[data-bg="img"] {
		padding-top: 0;
		padding-bottom: 0px;
		margin-bottom: 0;
	}

	&[data-bg="blue"],
	&[data-bg="gray"],
	&[data-bg="white"] {

		.header+&,
		#params+& {
			padding-top: 0;
		}
	}

	&[data-bg="white"] {
		background: #fff;
	}

	&[data-bg="gray"] {
		background: var(--c-gray);
	}

	&[data-bg="blue"] {
		background: var(--c-blue2);
		color: #fff;

		.block__head-link {
			color: #fff;

			a {
				color: #fff;
			}
		}
	}

	&[data-bg="photo-slider"] {
		border-radius: var(--border-radius) var(--border-radius) 0 0;
		margin-bottom: -40px;
		overflow: hidden;

		+.block {
			position: relative;
			// z-index: 4;
			border-radius: var(--border-radius) var(--border-radius) 0 0;
		}

		// +.block+.block,
		// +.block+.block,
		// +.block+.block+.block,
		// +.block+.block+.block+.block {

		// 	z-index: 5;

		// }
	}

	&[data-bg="gray"]+[data-bg="blue"],
	&[data-bg="white"]+[data-bg="blue"],
	&[data-bg="blue"]+[data-bg="gray"],
	&[data-bg="blue"]+[data-bg="white"],
	&[data-bg="white"]+[data-bg="gray"],
	&[data-bg="gray"]+[data-bg="white"] {

		border-radius: var(--border-radius) var(--border-radius) 0 0;
	}

	&[data-bg="blue"]+[data-bg="blue"] {
		transform: none !important;
		opacity: 1 !important;
	}

	&[data-bg="blue"]+[data-bg="blue"],
	&[data-bg="white"]+[data-bg="white"],
	&[data-bg="gray"]+[data-bg="gray"] {
		padding-top: 0;
	}



	// &[data-bg="photo-slider"]+.block {
	// 	padding-top: 110px;

	// 	@include mediaTablet {
	// 		padding-top: 60px;
	// 	}

	// 	@include mediaMobile {
	// 		padding-top: 40px;
	// 	}
	// }

	&._is-animated {
		opacity: 1;
		margin-top: 0px;
	}

	&__head-link {
		color: var(--c-text);
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: none;

		a {
			color: var(--c-text);
			display: flex;
			flex-direction: row;
			align-items: center;
			text-decoration: none;
		}

		img,
		svg {

			width: 40px;
			height: 40px;

			@include mediaMobile {
				width: 30px;
				height: 30px;
			}
		}
	}


	@include mediaTablet {
		padding-top: 60px;
		padding-bottom: 125px;
		margin-bottom: -60px;
	}

	@include mediaMobile {
		padding-top: 40px;
		padding-bottom: 85px;
		margin-bottom: -40px;
	}

	.top-block+& {
		padding-top: 0;
	}

	&.pt-0 {
		padding-top: 0;
	}


	&__head {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		gap: 10px;
		align-items: center;

		@include mediaTablet {
			margin-bottom: 20px;
		}

		.btn-arrow {
			min-width: initial;
		}

		.typography,
		h1,
		.h1,
		h2,
		.h2 {
			margin: 0;
			margin-right: auto;
		}

	}

	&__head-nav {
		display: flex;
		flex-direction: row;
		color: var(--c-text);
		gap: 4px;
		line-height: 0;



		button {
			background: #fff;
			width: 42px;
			height: 42px;
			border-radius: 16px;

			&.swiper-button-disabled {
				pointer-events: none;
				color: #828d9e;

			}

			&:hover {
				color: var(--c-contrast);
			}
		}

		img,
		svg {
			width: 24px;
			height: 24px;
		}
	}

	.h1,
	.h2,
	h2,
	h1 {
		&:first-child {
			margin-top: 0;
		}
	}
}

body:has(#params[data-bg="gray"]) {
	.top-block+.block[data-bg="white"] {
		padding-top: 110px;
		border-radius: var(--border-radius) var(--border-radius) 0 0;

		@include mediaTablet {
			padding-top: 60px;
		}

		@include mediaMobile {
			padding-top: 40px;
		}
	}
}

.btns-row {
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.block-wrapper:has([data-bg="blue"]) {
	overflow: hidden;
}



.icon-subtitle {
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	letter-spacing: -0.02em;
	color: #6e6e71;
	margin-bottom: 10px;
	align-items: center;
	margin-top: 10px;

	@include mediaTablet {
		font-size: 14px;
	}

	.h1+& {
		margin-top: -18px;

		@include mediaTablet {
			margin-top: -7px;
		}

		@include mediaTablet {
			margin-top: 0px;
		}

	}

	.typography+& {
		margin-top: 30px;
		margin-bottom: 44px;

		@include mediaTablet {
			margin-top: 10px;
			margin-bottom: 30px;
		}
	}

	img,
	svg {
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
		color: #DF5E4C;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}


.block-wrapper.block-main-intro {
	background: transparent !important;
	position: relative;
	z-index: 6;
	padding-bottom: 148px;
	margin-bottom: -96px;

	.block {
		overflow: visible;
	}



}

.block-wrapper:has(.block[data-bg=white])+.block-wrapper:has([data-bg=bg]),
.block-wrapper:has(.block[data-bg=gray])+.block-wrapper:has([data-bg=bg]) {
	padding-top: 0;
	margin-top: 0;
}


.block-wrapper:has([data-bg=bg])+.block-wrapper:has([data-bg=white]),
.block-wrapper:has([data-bg=bg])+.block-wrapper:has([data-bg=gray]) {
	padding-top: 0;
}