.restaurant-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px 16px;

	@include mediaMedium() {
		gap: 40px 10px;
		grid-template-columns: 1fr 1fr;
	}

	@include mediaTablet() {
		gap: 31px 12px;
	}

	@include mediaMobile() {
		grid-template-columns: 1fr;
		gap: 40px 12px;
	}

	;
}