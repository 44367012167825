@mixin menulink() {
	font-weight: 600;
	font-size: 24px;
	line-height: 133%;
	letter-spacing: -0.02em;
	color: #fff;
	text-decoration: none;

	@include mediaMedium {
		font-size: 20px;
		line-height: 120%;
		letter-spacing: -0.02em;
	}


}

.menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	color: #fff;
	pointer-events: none;
	touch-action: none;
	transition: opacity 0.4s;
	opacity: 0;

	&__close-icon {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&._open {
		opacity: 1;
		pointer-events: initial;
		touch-action: initial;
	}

	&__bg {
		position: absolute;
		left: 0;
		z-index: 1;
		top: 0;
		width: 100%;
		height: 100%;
		transition: backdrop-filter 0.3s;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);

		.menu._open-anim & {
			backdrop-filter: blur(60px);
			-webkit-backdrop-filter: blur(60px);
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
			background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.25) 100%);
		}
	}

	&__header {
		padding-top: 12px;
		padding-bottom: 12px;
		display: flex;
		flex-direction: row;
		align-items: center;

	}

	&__close {
		background: #fff;
		color: var(--c-text);
		padding: 8px 16px;
	}

	&__logo {
		margin-right: auto;
		filter: brightness(0.5) invert(1);

		a {
			line-height: 0;
			display: block;
		}
	}

	&__header,
	&__content {
		position: relative;
		z-index: 10;

	}

	&__content {
		padding-top: 100px;
		@include clamp("padding-left", 148px, 211px, 1366px, 1920px);
		@include clamp("padding-right", 148px, 211px, 1366px, 1920px);
		@include clamp("gap", 82px, 148px, 1366px, 1920px);

		@media(max-width:1366px) {
			@include clamp("padding-left", 30px, 148px, 768px, 1366px);
			@include clamp("padding-right", 30px, 148px, 768px, 1366px);
		}

		@media(max-width:768px) {
			padding-left: var(--inner-padding);
			padding-right: var(--inner-padding);
		}

		@include mediaMobile {
			padding-top: 70px;
		}

		margin-left: auto;
		margin-right: auto;
		display: grid;

		gap: 146px;
		position: relative;
		grid-template-columns: 1fr 3fr;

		@include mediaTablet {
			grid-template-columns: 1fr;
		}

	}

	&__second {
		position: relative;

		@include mediaTablet {
			display: none;

			.menu:has(.menu__second-wrap._active) & {
				display: block;
			}
		}

	}

	&__first {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100svh - 185px);

		@include mediaTablet {
			display: block;

			.menu:has(.menu__second-wrap._active) & {
				display: none;
			}
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}


	}

	&__second-menu-back {
		bottom: calc(100% + 30px);
		position: absolute;

		display: none;

		flex-direction: row;
		align-items: center;
		text-decoration: none;
		@include menulink();

		.menu:has(.menu__second-wrap._active) & {
			display: flex;
		}

		@include mediaTablet() {
			font-size: 18px;


			svg {
				transform: rotate(-90deg);
				order: -1;
			}

			&:hover {
				svg {
					transform: rotate(-90deg);
				}
			}
		}

		svg {
			width: 30px;
			height: 30px;
			transition: transform 0.4s;
		}

		&:hover {
			color: var(--c-contrast);

			svg {
				transform: rotate(45deg);
			}
		}
	}

	&__dop-menu {
		display: flex;
		flex-direction: column;
		gap: 10px;


		a {
			color: #fff;
			text-decoration: none;

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	&__first-menu {
		margin-bottom: 30px;
		gap: 20px;
		display: flex;
		flex-direction: column;

		&:has(a._active) {
			a {
				opacity: 0.3;
				transition: opacity 0.4s;

				&._active {
					opacity: 1;
				}
			}
		}

		a {
			display: flex;
			flex-direction: row;
			@include menulink();
			align-items: center;
			color: #fff;
			text-decoration: none;

			svg {
				margin-left: auto;
				width: 24px;
				height: 24px;
				transition: transform 0.4s;
			}

			&:hover {
				svg {
					transform: rotate(45deg);
				}
			}
		}
	}

	&__second-grid {
		grid-template-columns: auto auto auto;
		gap: 60px;
		display: grid;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100svh - 185px);

		@include mediaTablet {
			grid-template-columns: 1fr 1fr;
			gap: 50px;
			max-height: calc(100svh - 141px);
		}

		@include mediaMobile {
			grid-template-columns: 1fr;
			gap: 40px;
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	&__second-menu {
		display: flex;
		flex-direction: column;
		gap: 10px;

		&>li>a {
			@include menulink();

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	&__second-wrap {
		display: none;

		&._active {
			display: block;
		}
	}

	&__second-more-link {
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #fff;
		text-decoration: none;

		svg {
			width: 22px;
			height: 22px;
			transition: transform 0.4s, color 0.4s;
		}

		&:hover {
			color: var(--c-contrast);

			svg {
				transform: rotate(45deg);
			}
		}
	}

	&__second-third {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin: 20px 0;

		a {
			color: #fff;
			text-decoration: none;

			@include mediaTablet() {
				line-height: 143%;
				letter-spacing: -0.01em
			}

			&:hover {
				color: var(--c-contrast);
			}
		}
	}
}