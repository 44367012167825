.gallery-row {
	position: relative;
	overflow: visible;

	&__prev {
		left: 0%;
	}

	&__video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
			z-index: 5;
			background: rgba($color: #000000, $alpha: 0.4);
		}

		&::after {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 7;
			transform: translateX(-50%) translateY(-50%);
			width: 30px;
			height: 30px;
			background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9883 8.11854C11.7978 7.97998 11.5472 7.96146 11.339 8.07053C11.1308 8.17961 11 8.39799 11 8.63637V21.3636C11 21.602 11.1308 21.8204 11.339 21.9295C11.5472 22.0385 11.7978 22.02 11.9883 21.8815L20.7383 15.5178C20.9025 15.3984 21 15.2055 21 15C21 14.7945 20.9025 14.6016 20.7383 14.4822L11.9883 8.11854Z' fill='white'/%3E%3C/svg%3E%0A");
			display: block;
			content: '';

		}
	}

	&__next {
		right: 0;
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		z-index: 11;
		transform: translateY(-50%);
	}

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-slide {
		line-height: 0;
		border-radius: var(--border-radius);
		overflow: hidden;

		img[data-galleryslider] {
			transition: transform 0.5s;
			cursor: pointer;

			&:hover {
				transform: scale(1.01);
			}
		}

		&:has(._vertical) {
			aspect-ratio: 438/658;

			@include clamp("max-width", 314px, 438px, 1366px, 1920px);

			@media(max-width:1367px) {
				@include clamp("max-width", 258px, 314px, 768px, 1366px);
			}

			@media(max-width:769px) {
				max-width: min(258px, calc(100% - var(--inner-padding) *2));
			}

		}

		&:has(._square) {
			aspect-ratio: 1/1;
			max-width: 500px;
			@include clamp("max-width", 380px, 500px, 1366px, 1920px);

			@media(max-width:1367px) {
				@include clamp("max-width", 348px, 380px, 768px, 1366px);
			}

			@media(max-width:769px) {
				max-width: min(300px, calc(100% - var(--inner-padding) *2));
			}
		}


		&:has(._horizontal, .gallery-row__video) {
			aspect-ratio: 589/388;
			max-width: 589px;
			@include clamp("max-width", 422px, 589px, 1366px, 1920px);

			@media(max-width:1367px) {
				@include clamp("max-width", 348px, 422px, 768px, 1366px);
			}

			@media(max-width:769px) {
				max-width: min(348px, calc(100% - var(--inner-padding) *2));
			}
		}

		img {
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			// &._vertical {
			// 	aspect-ratio: 438/658;
			// }

			// &._square {
			// 	aspect-ratio: 1/1;
			// }

			// &._horizontal {
			// 	aspect-ratio: 589/388;
			// }
		}
	}
}

.block:has(.gallery-row) {
	overflow: hidden;
}

.popup-gallery {
	.popup__close {
		right: 30px;
		top: 30px;
	}
}

.gallery-popup-row {
	width: 100%;

	.swiper-wrapper:has(.swiper-slide-active.swiper-slide-next) {
		justify-content: center;
	}

	.swiper-slide:has(video) {
		min-width: 50vw;
		// max-width: calc(100vw - 100px) !important;

		// video {
		// 	width: 100%;
		// }
	}

	.gallery-popup-row__video,
	video {
		min-width: 50vw;
	}

	.gallery-popup-row__video,
	video,
	img {
		max-width: 50vw;
		display: block;
		max-height: calc(100svh - 135px);
	}

	.swiper-wrapper {
		align-items: center;
	}

	&__prev {
		left: 40px;
	}

	&__next {
		right: 40px;
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		z-index: 10;
		transform: translateY(-50%);
	}
}