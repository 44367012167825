	body:has(.popup._open) {
		overflow: hidden;

		.header {
			top: -110px;
		}
	}

	.block:has(.popup._open) {
		z-index: 20;
	}

	.popup {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 92000;
		transform: opacity 0.4s;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		touch-action: none;


		.text-block {
			max-width: 100% !important;

			&.typography {

				&>img,
				&>p>img {
					@include mediaTablet {
						aspect-ratio: 595/318;
						object-fit: cover;
					}

					@include mediaMobile {
						aspect-ratio: 280/219;
						object-fit: cover;
					}
				}
			}
		}

		&._open {
			pointer-events: initial;
			touch-action: initial;
			opacity: 1;
			visibility: visible;
		}

		&__close {
			position: absolute;
			color: #fff;
			right: -25px;
			top: -25px;
			cursor: pointer;
			transition: color 0.3s;

			@include mediaMobile {
				right: initial;
				left: 50%;
				top: -35px;
				transform: translateX(-50%);
			}

			&:hover {
				color: var(--c-contrast);
			}

			svg {
				width: 30px;
				height: 30px;
			}
		}

		&__overlay {
			opacity: 0.6;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			background: #202c41;
		}

		&__flex {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			height: 100svh;
			width: 100%;
		}

		&__content {
			max-width: min(1060px, calc(100% - 100px));
			padding: 0px 30px;
			background: #fff;
			border-radius: 30px;
			position: relative;
			z-index: 1100;

			@include mediaMedium {
				max-width: min(1060px, 100% - 111px);
			}

			@include mediaMobile {
				max-width: min(1060px, calc(100% - 40px));
				padding: 0px 14px;
				border-radius: 15px;
			}



			.text__row {
				max-width: 100%;
			}

		}

		&__scroll {
			overflow: auto;
			overflow-x: hidden;
			padding: 40px 0;
			height: 100%;
			max-height: calc(100svh - 100px);

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}
	}