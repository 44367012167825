.numbers-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 72px 16px;

	@include mediaMedium {
		grid-template-columns: 1fr 1fr;
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
	}

	&._columns-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 40px 16px;

		@include mediaMedium {
			grid-template-columns: 1fr 1fr 1fr;
			gap: 40px 10px;
		}

		@include mediaTablet {
			grid-template-columns: 1fr 1fr;
			gap: 40px 12px;
		}

		@include mediaMobile {
			grid-template-columns: 1fr;
		}
	}
}

.number-preview {
	position: relative;



	&__img-wrap {
		margin-bottom: 20px;
		position: relative;
		aspect-ratio: 589/422;
		overflow: hidden;

		@media(min-width:1600px) {
			.numbers-grid._columns-4 & {
				aspect-ratio: 438/290;
			}
		}

		@include mediaMedium() {
			aspect-ratio: 638/422;
		}

		@include mediaTablet() {
			min-height: 230px;
			aspect-ratio: initial;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.4s;

			.number-preview:hover & {
				transform: scale(1.02);
			}
		}


	}

	&__title {
		text-decoration: none;
		color: var(--c-text);

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	.btn-arrow {
		min-width: initial;
	}

	&__params {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 4px;

		@include mediaTablet {
			flex-direction: column
		}

		li {
			font-weight: 300;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.01em;
			color: #6e6e71;

			@include mediaTablet {
				font-size: 14px;
				line-height: 143%;
			}

			&::after {
				content: '|';
				display: inline-block;
				margin-left: 4px;
				margin-right: 4px;

				@include mediaTablet {
					display: none;

				}
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	&__btns {
		display: flex;
		flex-direction: row;
		gap: 10px;
		margin-top: 20px;
		position: relative;
		z-index: 4;
	}
}