.feedback-form {

	color: #fff;
	overflow: hidden;

	.swiper-slide {
		background: var(--c-blue2);
		height: auto;
		padding: 80px;
		overflow: hidden;

		@include mediaTablet() {
			padding: 40px 56px;
		}

		@include mediaMobile() {
			padding: 40px 20px;
		}
	}

	&__form {}

	h2 {
		margin-bottom: 23px;
		text-align: left;

		@include mediaMedium() {
			margin-bottom: 37px;
		}

		@include mediaTablet() {
			margin-bottom: 34px;
		}

		@include mediaMobile() {
			margin-bottom: 30px;
		}
	}

	&__sbmt.btn {
		color: var(--c-blue2);
		background: #fff;
		margin-top: 9px;

		&:hover {
			color: #fff;
			background: var(--c-contrast);
		}
	}

	&__inner {


		margin-right: auto;

		h3,
		.h3,
		h1,
		.h2,
		.h1,
		.h2 {
			text-align: center;
		}
	}

	&__grid {
		display: grid;
		gap: 20px 10px;
		max-width: 1165px;
		grid-template-columns: 1fr 1fr 1fr;

		._col-3 {
			grid-column: span 3;

			@include mediaTablet() {
				grid-column: span 2;
			}

			@include mediaMobile() {
				grid-column: span 1;
			}
		}

		@include mediaTablet() {
			grid-template-columns: 1fr 1fr;
		}

		@include mediaMobile() {
			grid-template-columns: 1fr;
			gap: 10px 10px;
		}


	}

	&__agree {
		margin-top: 33px;
		max-width: 420px;
		margin-bottom: 0;
	}
}

.form1.form1.form1 {
	overflow: hidden !important;
}

.form1 {
	color: #fff;

	.swiper-slide {
		background: var(--c-blue2);
		height: auto;
		padding: 80px;
		overflow: hidden;

		@include mediaTablet() {
			padding: 40px 56px;
		}

		@include mediaMobile() {
			padding: 40px 20px;
		}
	}

	h2 {
		margin-bottom: 23px;
		text-align: center;

		@include mediaMedium() {
			margin-bottom: 37px;
		}

		@include mediaTablet() {
			margin-bottom: 34px;
		}

		@include mediaMobile() {
			margin-bottom: 30px;
		}
	}

	&__sbmt.btn {
		color: var(--c-blue2);
		background: #fff;
		margin-top: 9px;

		&:hover {
			color: #fff;
			background: var(--c-contrast);
		}
	}

	&__inner {
		max-width: 774px;
		margin-left: auto;
		margin-right: auto;

		h3,
		.h3,
		h1,
		.h2,
		.h1,
		.h2 {
			text-align: center;
		}
	}

	&__grid {
		display: grid;
		gap: 20px 10px;
		grid-template-columns: 1fr 1fr;

		@include mediaMobile() {
			grid-template-columns: 1fr;
			gap: 10px 10px;
		}


	}

	&__agree {
		margin-top: 33px;
		max-width: 420px;
		margin-bottom: 0;
	}
}

.btn {
	font-weight: 600;
	font-size: 14px;
	line-height: 157%;
	letter-spacing: -0.02em;
	padding: 10px 16px;
	border-radius: 16px;
	transition: background 0.4s, color 0.4s;
	background: var(--c-contrast);
	color: #fff;
	text-decoration: none;
	display: inline-block;

	&._style-dark {
		background: var(--c-blue2);
		color: #fff;

		&:hover {
			background: var(--c-contrast);

		}
	}

	&:hover {
		color: #fff;
		background: var(--c-blue2);
	}


}


.btns-center {
	display: flex;
	margin-top: 20px;
	flex-direction: row;
	gap: 20px;
	justify-content: center;
}

.btn-arrow {
	border-radius: 16px;
	padding: 9px 16px;
	border: 1px solid var(--c-border);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	min-width: 314px;
	color: var(--c-text);
	transition: border 0.5s;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 143%;
	letter-spacing: -0.01em;
	transition: background 0.3s, color 0.3s;

	@include mediaMobile {
		min-width: initial;
	}

	[data-bg="blue"] & {
		background: transparent;
		color: #fff;
		border-color: #fff;

		&:hover {
			color: var(--c-text);
			background: #fff;
		}
	}

	&._style-white {
		background: #fff;
		color: var(--c-blue2);
		border: 1px solid var(--c-blue2);

		&:hover {
			color: #fff;
			background: var(--c-text);
			border: 1px solid var(--c-blue2);
		}
	}

	&:hover {
		background: var(--c-blue2);
		color: #fff;
	}



	@include mediaMedium {
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
	}

	@include mediaMedium {}

	@include mediaMedium {}

	@include mediaMedium {}

	&:hover {
		border-color: var(--c-blue2);

		svg {
			transform: rotate(45deg);
		}
	}

	&::after {
		// position: absolute;
		// left: 0;
		// top: 0;
		// width: 100%;
		// height: 100%;
		// display: block;
		// content: '';
	}

	svg {
		margin-left: auto;
		max-width: 22px;
		max-height: 22px;
		transition: transform 0.5s;
	}
}

.btn-arrow-mini {
	color: var(--c-text);
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 143%;
	letter-spacing: -0.01em;
	transition: color 0.3s;

	[data-bg="blue"] & {
		color: #fff;
	}

	svg {
		max-width: 30px;
		max-height: 30px;
		transition: transform 0.5s;
	}
}

.btn-border {
	border-radius: 30px;
	padding: 9px 16px;
	border: 1px solid var(--c-blue2);
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--c-text);
	transition: border 0.5s;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 143%;
	letter-spacing: -0.01em;
	transition: background 0.3s, color 0.3s;


	@include mediaMobile {
		padding-left: 10px;
		padding-right: 10px;
	}

	&._style-dark {
		background: var(--c-blue2);
		color: #fff;

		&:hover {
			color: var(--c-text);
			background: #fff;
			border: 1px solid var(--c-blue2);
		}
	}

	&:hover {
		background: var(--c-blue2);
		color: #fff;
	}

	@include mediaMedium {
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
	}

	@include mediaMedium {}

	@include mediaMedium {}

	@include mediaMedium {}

	&:hover {
		border-color: var(--c-blue2);

		svg {
			transform: rotate(45deg);
		}
	}

	&::after {
		// position: absolute;
		// left: 0;
		// top: 0;
		// width: 100%;
		// height: 100%;
		// display: block;
		// content: '';
	}


}

.form-checkbox {
	position: relative;
	padding-left: 34px;
	display: block;

	&__title {
		font-weight: 500;
		font-size: 12px;
		line-height: 133%;
		letter-spacing: -0.01em;
		display: block;
		margin-bottom: 8px;
	}

	&__input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		opacity: 0;
		height: 100%;
		z-index: 5;
	}

	&__indicator {
		border-radius: 4px;
		width: 18px;
		height: 18px;
		background: #fff;
		position: absolute;
		left: 3px;
		top: 1px;
		border: 1px solid var(--c-gray2);
		display: block;

		input:checked+& {
			border-color: #82c55c;
			background-color: #82c55c;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L3.57143 5L7 1' stroke='white' stroke-width='1.4'/%3E%3C/svg%3E%0A");
		}

		input.error+& {
			border-color: #df5d4c;
			background-color: #df5d4c;
			background-position: center;
			background-repeat: no-repeat;
		}

		input.error+& {
			color: #df5d4c;
		}
	}
}

.form-text {
	&__title {
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		display: block;
		margin-bottom: 8px;
	}

	&__textarea {
		min-height: 99px;
	}

	&__textarea,
	&__input {
		padding: 13px 16px;
		border-radius: 12px;
		background: #fff;
		font-weight: 300;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		border: 1px solid var(--c-gray2);
		display: block;
		width: 100%;
		outline: none !important;
		color: var(--c-text);

		&.error {
			color: #df5d4c;

			&::placeholder {
				color: #df5d4c;
			}
		}

		&::placeholder {
			color: var(--c-gray2);
		}

		&:hover::placeholder {
			color: #606981;
		}
	}
}

.select2-dropdown {
	margin-top: 5px;
	border: 0 solid;
	border-radius: 16px !important;
	padding: 10px 16px;
}

.select2-results__options {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.select2-results__option {
	font-weight: 400;
	font-size: 14px;
	line-height: 179%;
	letter-spacing: -0.01em;
	background: transparent !important;
	padding: 0;

	&.select2-results__option--highlighted {
		color: var(--c-contrast) !important;
	}
}