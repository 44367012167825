.block-restaurants-more {
	overflow: hidden;
}

.columns-3 {
	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 16px;

		@include mediaMedium {
			gap: 10px
		}

		@include mediaTablet {
			gap: 16px;
			grid-template-columns: 1fr;
		}
	}

	&__column {
		background: var(--c-gray);
		padding: 30px 20px;
		flex-direction: column;
		display: flex;

		dl {
			gap: 20px
		}

		h3 {
			@include clampf(20px, 24px, 1366px, 1920px);
			font-weight: 600;
		}

		p {
			font-size: 16px;

			@include mediaTablet {
				font-size: 14px;
			}
		}

		[data-bg="gray"] & {
			background: #fff;
		}
	}
}

.file-downloads {
	margin-top: auto !important;
	list-style: none !important;
	padding-left: 0 !important;

	li {
		list-style: none !important;
		padding-left: 0 !important;
		margin-bottom: 20px !important;

		&:last-child {
			margin-bottom: 0px !important;

			.file-download {
				margin-bottom: 0px !important;
			}
		}

		@include mediaMobile {
			span {}


		}

		&::after {
			display: none !important;
		}
	}
}

.file-download {
	padding: 0;
	display: flex;
	font-weight: 700;
	font-size: 14px;
	line-height: 143%;
	letter-spacing: -0.02em;
	text-decoration: none;
	color: var(--c-text);

	@include mediaMobile {
		font-weight: 500;
	}

	&:hover {
		color: var(--c-contrast);
	}

	img,
	svg {
		width: 24px;
		height: 24px;
		margin-left: auto;
	}
}