.price-page {
	background: #fff;
	padding: 30px 20px;

	.typography {
		max-width: 1329px;
	}

	.table-price {
		background: transparent;
		padding: 0;
		border-radius: 0;
		max-width: 50%;

		@include mediaTablet {
			max-width: 100%;
		}

	}

	.table-price tr td {
		@media(min-width:1500px) {
			font-size: 20px;
		}
	}

	.btn-arrow {
		max-width: 50%;

		@include mediaTablet {
			max-width: 100%;
		}
	}

	ul {
		max-width: 50%;
		max-width: 50%;

		&._column-2 {
			max-width: 100%;
		}

		@include mediaTablet {
			max-width: 100%;
		}
	}
}