.mapsite {
	&__grid {
		display: grid;
		grid-template-columns: auto auto auto auto;
		grid-template-areas: "it1 it2 it3 it4";
		gap: 40px 20px;

		@include mediaTablet() {
			grid-template-columns: auto auto auto;
			grid-template-rows: auto auto;
			grid-template-areas:
				"it1 it2 it3"
				"it1 it4 it4";

		}

		@include mediaMobile() {
			grid-template-columns: 1fr;
			grid-template-rows: initial;
			grid-template-areas: initial;
		}
	}

	&__column {
		&:nth-child(1) {
			grid-area: it1;
		}

		&:nth-child(2) {
			grid-area: it2;
		}

		&:nth-child(3) {
			grid-area: it3;
		}

		&:nth-child(4) {
			grid-area: it4;
		}
	}

	&__level2 {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 10px;

		a {
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.02em;
			text-decoration: none;
			color: var(--c-text);

			@include mediaMedium {
				font-size: 14px;
			}

			&:hover {
				color: var(--c-contrast);
			}
		}
	}

	&__level1 {
		display: flex;
		flex-direction: column;
		gap: 10px;

		&>li>a {
			font-weight: 300;
			font-size: 20px;
			line-height: 140%;
			letter-spacing: -0.01em;
			color: var(--c-text);
			text-decoration: none;

			@include mediaMedium {
				font-size: 16px;
			}

			&:hover {
				color: var(--c-contrast);
			}
		}
	}
}