.block-wrapper.block-page-404 {
	padding: 0;
	margin: 0
}

.p404 {
	width: 100%;
	min-height: 100svh;
	position: relative;
	overflow: hidden;
	padding: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;

	&__content {
		position: relative;
		z-index: 5;
	}

	&__btn {
		background: #fff;
		color: #828d9e;

		&:hover {
			background: #fff;
			color: var(--c-text);
		}
	}

	&__logo {
		width: 300px;
		display: block;

		@include mediaMedium {
			width: 130px;
		}

		svg {
			width: 100%;
		}
	}

	&__footer {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 3;
		padding-bottom: 71px;
		display: flex;
		flex-direction: row;
		justify-content: center;

		@include mediaMedium {
			padding-bottom: 39px;
		}

		@include mediaTablet {
			padding-bottom: 63px;
		}
	}

	&__bg-icon {
		height: max(650px, calc(100svh - 140px));
		max-width: initial;
		position: absolute;
		left: 50%;
		top: 50%;
		mix-blend-mode: soft-light;
		transform: translateX(-50%) translateY(-50%);
	}

	&__bg-wrap {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}