.top-front {
	min-height: calc(100svh - 130px);

	position: relative;
	display: flex;
	flex-direction: column;
	overflow: visible;

	@include mediaMobile() {
		min-height: 0;
	}

	&__about-link {
		display: flex;
		flex-direction: row;
		gap: 3px;
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		text-decoration: none;

		svg {
			width: 22px;
			height: 22px;
		}
	}

	&__bg-content {
		position: relative;
		z-index: 5;
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: var(--border-radius);
		overflow: hidden;

		@include mediaMobile {
			.headline {
				padding: 0 !important;
				margin-bottom: 10px !important;
			}

			aspect-ratio: 4 / 3;
			position: relative;
			height: initial;
			overflow: hidden;
			border-radius: var(--border-radius);
			padding: 16px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0.2;
			display: block;
			content: '';
		}
	}



	&__content {
		display: flex;
		flex-direction: row;
		gap: 30px;
		margin-top: auto;
		position: relative;
		z-index: 3;
		align-items: flex-end;

		@include mediaTablet() {
			flex-direction: column;
			gap: 0;
		}

	}

	&__corner-1,
	&__corner-2,
	&__corner-3,
	&__corner-4 {
		color: #fff;
		width: 40px;
		position: absolute;
		height: 40px;

		.block[data-bg="gray"] & {
			color: var(--c-gray);
		}
	}

	&__corner-1 {
		left: -1px;
		top: -39px;

		transform: rotate(-90deg);
	}

	&__corner-2 {
		right: -39px;
		bottom: -1px;

		transform: rotate(-90deg);
	}

	.headline {
		color: #fff;
		margin-bottom: 40px;

	}

	&__left {
		max-width: 40%;
		margin-right: auto;
		padding-top: 33px;
		transition: transform 0.8s;
		transform: translateY(100%);

		._is-animated & {
			transform: translateY(-0%);
		}

		@include mediaTablet() {
			max-width: 100%;
			width: 100%;
			min-height: 410px;
			display: flex;
			flex-direction: column;

			.headline {
				margin-bottom: auto;
			}
		}

		@include mediaMobile() {
			padding-top: 0;
			min-height: initial;
		}
	}

	&__left-bg,
	.headline {
		padding-left: 55px;

		@include mediaTablet() {
			padding-left: 20px;
		}
	}

	&__left-bg {
		background: #fff;
		padding-top: 30px;
		padding-bottom: 20px;
		padding-right: 40px;
		border-radius: 0 50px 0 0;

		position: relative;

		@include mediaTablet() {
			padding: 20px;
			padding-bottom: 0;
			max-width: 50%;
		}

		@include mediaMobile() {
			border-radius: 0;
			max-width: 100%;
		}

		a,
		p {
			max-width: 535px;

			@include mediaMedium {
				max-width: 382px;
			}
		}

		.block[data-bg="gray"] & {
			background: var(--c-gray);
		}
	}

	&__right {
		background: #fff;
		border-radius: 40px 0 0 0;
		position: relative;
		padding: 27px;
		padding-bottom: 45px;
		width: 30%;
		transition: transform 0.8s;
		transform: translateY(100%);

		._is-animated & {
			transform: translateY(-0%);
		}


		@include mediaTablet() {
			width: 100%;
			border-radius: 0;
			padding: 20px;
			padding-bottom: 0;
			padding-top: 40px;
		}

		@include mediaMobile() {
			padding-top: 0;
		}

		.block[data-bg="gray"] & {
			overflow: visible;
			background: var(--c-gray);
		}
	}

	&__corner-3 {
		right: -1px;
		top: -39px;

		transform: rotate(180deg);
	}

	&__corner-4 {
		left: -39px;
		bottom: -1px;

		transform: rotate(180deg);
	}
}

.traveline-front {

	.traveline {}

	.traveline__form {
		flex-direction: column;
		display: flex;
		gap: 6px;
		position: relative;
		bottom: 0;
		left: 0;
		top: 0;
		transform: translateX(0);
	}

	&__column {
		position: relative;

	}



}

.block-main-intro {
	position: relative;
	padding-top: 0;
	z-index: 8;

	.block {
		background: transparent !important;
	}
}