.test {
	padding: 30px 0 50px;
	margin-bottom: 30px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;



	&__list {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__item {
		position: relative;
		line-height: 0;

		iframe {
			box-shadow: 0 0 0 1px #ccc;
		}

		&.test__1920 {
			width: 1920px;
		}

		&.test__1366 {
			width: 1366px;
		}

		&.test__768 {
			width: 768px;
		}

		&.test__360 {
			width: 360px;
		}
	}

	&__item-diff {
		position: absolute;
		left: 0;
		cursor: not-allowed;


		top: 0;

		.diff-hidden & {
			display: none;
		}
	}

	&__item-head {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}

	&__item-body {
		position: relative;
	}

	&__item-lock {
		opacity: 0.5;

		.test__item:has(.test__item-maket._lock) & {
			opacity: 1;
		}

		svg {
			width: 15px;
		}
	}

	&__item-shot {
		border: 1px solid #ccc;
		padding: 5px;
		display: block;
		line-height: 120%;
		border-radius: 5px;
		font-size: 12px;

	}

	&__item-maket {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		cursor: grab;
		opacity: var(--test-opacity);
		box-shadow: 0 0 0 1px red;

		._test-lock & {
			pointer-events: none;
		}

		._test-invert & {
			filter: invert(1);
		}
	}

	&__item-width {
		font-size: 12px;
	}

	&__title {
		font-size: 30px;
		margin-bottom: 15px;
	}

}

html {
	--test-opacity: 0.5
}

.test-bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 10px;
	width: 100%;
	box-shadow: 0 0 8px 0px #000;
	background: #fff;
	background: antiquewhite;
	gap: 20px;

	svg {
		width: 20px;
	}

	&__lock {
		cursor: pointer;
		opacity: 0.5;

		._test-lock & {
			opacity: 1;
		}


	}

	&__invert {
		opacity: 0.6;
		cursor: pointer;

		._test-invert & {
			opacity: 1;
		}
	}
}