.bg-block {
	position: relative;
	overflow: hidden;
	min-height: 56.25vw;
	display: flex;
	flex-direction: row;
	padding: 0 !important;

	@include mediaTablet {
		min-height: max(100svh, 500px);
	}

	&__content {
		position: relative;
		z-index: 4;
		color: #fff;
		padding-bottom: 70px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		text-align: right;
		justify-content: flex-end;

		@include mediaMedium {
			padding-bottom: 60px;
		}

		@include mediaTablet {
			padding-bottom: 30px;
		}

		h2 {
			margin-bottom: 20px;

			@include mediaMedium {
				margin-bottom: 15px;
			}

			@include mediaTablet {
				margin-bottom: 10px;
			}
		}

		p {
			font-size: 20px;
			max-width: 60%;
			line-height: 143%;
			letter-spacing: -0.01em;
			margin-bottom: 10px;

			@include mediaMedium {
				font-size: 16px;
			}

			@include mediaMobile {
				font-size: 14px;
				max-width: 100%;
			}
		}

		p+.icon-subtitle,
		h2+.icon-subtitle,
		.h1+.icon-subtitle {
			margin-top: 30px;

			@include mediaMedium {
				margin-top: 20px;
			}

			@include mediaTablet {
				margin-top: 0;
			}
		}

		.icon-subtitle {
			color: #fff;

			@include mediaMobile {
				font-size: 14px;
				max-width: 70%;
			}

			svg {
				color: #fff;
			}
		}
	}

	&__bg {
		z-index: 3;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			display: block;
			content: '';
			z-index: 11;


			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			display: block;
			content: "";
		}

		img,
		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			object-fit: cover;
		}
	}
}

.bg-block2 {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	min-height: 38.75vw;

	@include mediaTablet() {
		min-height: 400px;
	}

	@include mediaMobile() {
		height: 652px;
	}

	&__content {
		padding: 40px 50px;
		z-index: 5;

		@include mediaTablet() {
			padding: 30px;
		}

		@include mediaMobile() {
			padding: 36px 20px;
		}

		color: #fff;

	}

	.btn-arrow {
		width: fit-content;
		color: #fff;
		margin-top: 40px;
		border-color: #fff;

		&:hover {
			background: #fff;
			color: var(--c-blue);
		}

		@include mediaTablet() {
			margin-top: 20px;
		}
	}

	&__bg {
		z-index: 3;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			display: block;
			content: '';
		}
	}
}