.events-pages-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px 16px;

	@include mediaMedium {
		grid-template-columns: 1fr 1fr;
		gap: 40px 10px;
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
		gap: 30px 12px;
	}

	@include mediaMobile {
		grid-template-columns: 1fr;
		gap: 40px 12px;
	}
}

.events-page-preview {
	position: relative;

	&__link {
		color: var(--c-text);
		text-decoration: none;

		[data-bg="blue"] & {
			color: #fff;

			&:hver {
				color: var(--c-contrast);
			}
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	&__img-wrap {
		aspect-ratio: 585/422;
		position: relative;
		margin-bottom: 20px;

		@include mediaMedium {
			aspect-ratio: 638/422;
		}

		@include mediaTablet {
			aspect-ratio: 348/230;
		}

		@include mediaMobile {
			aspect-ratio: 320/240;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.5s;

			.events-page-preview:hover & {
				transform: scale(1.01);
			}
		}
	}
}

.clients {
	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 18px;

		@include mediaMedium {
			gap: 10px;
		}

		@include mediaTablet {
			grid-template-columns: 1fr 1fr;
			gap: 16px;
		}

		@include mediaMobile {
			gap: 8px;
		}
	}
}

.client {
	background-color: #fff;
	border-radius: 20px;
	padding: 30px;
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;
	position: relative;
	aspect-ratio: 436/220;

	@include mediaMedium {
		aspect-ratio: 314/200;
	}

	@include mediaTablet {
		aspect-ratio: 346/200;
	}

	@include mediaMobile {
		aspect-ratio: 156/100;
	}

	img {
		object-fit: contain;
		max-width: 200px;
		max-height: 128px;
		transition: transform 0.6s;
		width: 100%;
		height: 100%;

		@include mediaMedium {
			max-width: 175px;
			max-height: 128px;
		}

	}

	&:hover img {}
}

a.client:hover img {
	transform: scale(1.1);
}