.files-block {
	min-height: 100svh;
	z-index: 7;

	position: relative;

	&__text {
		color: #Fff;
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		p {
			@include mediaMedium {
				font-size: 14px;
				;
			}
		}
	}

	&__menu {
		margin-top: 141px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 484px;
		width: 100%;
		max-width: 1194px;
		margin-left: auto;
		margin-right: auto;

		@include mediaMedium {
			max-height: 338px;
			margin-top: 80px;
			max-width: 854px;
		}

		@include mediaTablet {
			min-height: initial;
			gap: 40px;
			flex-direction: column;
			margin-top: 40px;
			max-width: 528px;
		}

		@include mediaMobile {
			gap: 6px;
		}
	}

	&__item {
		background: #fff;
		padding: 20px;
		max-width: 286px;
		width: 100%;
		position: relative;
		min-height: 200px;
		display: flex;
		flex-direction: column;

		@include mediaMedium {
			max-width: 206px;

		}

		@include mediaMobile {
			max-width: 238px;
			min-height: 142px;
		}

		@include mediaTablet {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}

		&:nth-child(1) {
			margin-bottom: auto;

			@include mediaTablet {
				margin-bottom: initial;
				margin-right: auto;
			}
		}

		&:nth-child(2) {
			margin-top: auto;

			@include mediaTablet {
				margin-top: initial;
				margin-left: auto;
			}
		}

		&:nth-child(3) {
			margin-bottom: auto;
			margin-top: auto;

			@include mediaTablet {
				margin-top: initial;
				margin-bottom: initial;
				margin-left: 25%;
			}

			@include mediaMobile {
				margin-left: 8%;
			}
		}
	}

	&__item-title {
		color: var(--c-text);
		text-decoration: none;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	&__item-icon {
		width: 34px;
		height: 34px;
		margin-bottom: auto;
		color: var(--c-contrast);
		display: block;
	}

	&__item-download {
		display: flex;
		color: var(--c-text);
		font-weight: 500;
		font-size: 14px;
		line-height: 143%;
		letter-spacing: -0.01em;
		align-items: center;
		gap: 15px;
		flex-direction: row;

		.files-block__item:hover & {
			color: var(--c-contrast);
		}



		svg {
			margin-left: auto;
			width: 24px;
			height: 24px;
		}
	}

	&__content {
		padding-top: 56px;
		padding-bottom: 56px;
		position: relative;
		z-index: 4;

		p {
			margin-top: 20px;
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
		}

		@include mediaMobile {
			padding: 50px 20px;
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0.5;
		}
	}
}