.award-preview {
	position: relative;
	overflow: hidden;

	@media(max-width:992px) {
		max-width: 258px;
		margin-bottom: 20px;
	}


	@include mediaMobile {
		// max-width: 100%;
	}

	&__img-wrap {
		aspect-ratio: 436/278;
		margin-bottom: 20px;

		@include mediaMedium {
			aspect-ratio: 422/278;
		}

		@include mediaTablet {
			aspect-ratio: 258/170;
		}

		@include mediaMobile {
			aspect-ratio: 320/230;
		}

		position: relative;
		overflow: hidden;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.4s;

			.award-preview:hover & {
				transform: scale(1.02);
			}
		}
	}

	&__title {
		text-decoration: none;
		color: var(--c-text);
		margin-bottom: 10px;
		display: block;

		&:hover {
			color: var(--c-contrast);
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
		}
	}

	p {
		@include mediaMobile {
			display: none;
		}
	}
}

.award-slider {
	overflow: hidden;

	.block__head-nav {
		display: flex !important;
	}

	&__prev,
	&__next {
		@include mediaTablet {
			background: transparent;
		}
	}

	&__swiper {
		overflow: visible !important;

		.swiper-wrapper {
			// @include mediaMobile {
			// 	transform: none !important;
			// 	flex-direction: column !important;
			// }
		}
	}

}