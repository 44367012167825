.about-service-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 16px;

	@include mediaMedium {
		gap: 10px
	}

	@include mediaTablet {
		grid-template-columns: 1fr 1fr;
		gap: 12px
	}

	@include mediaMobile {
		gap: 8px
	}
}

.about-service {
	border-radius: 30px;
	background: var(--c-gray);
	padding: 30px 20px;
	min-height: 170px;



	@include mediaMedium {
		min-height: 126px;
	}

	@include mediaTablet {
		min-height: 140px;
		padding: 30px 20px;
	}

	@include mediaMobile {
		min-height: 140px;
		padding: 20px 14px;
	}

	&:hover {
		color: var(--c-contrast);
	}

	text-decoration: none;
	color: var(--c-text);
	font-weight: 600;
	line-height: 133%;

	letter-spacing: -0.01em;
	@include clampf(20px, 24px, 1366px, 1920px);

	@media(max-width:1366px) {
		@include clampf(18px, 20px, 768px, 1366px);
	}

	@media(max-width:768px) {
		line-height: 167%;
		@include clampf(12px, 18px, 360px, 768px);
	}
}