@use 'sass:math';

$mobile-width: 719px;
$tablet-width: 1100px;
$medium-width: 1600px;

@mixin mediaMobile {
	@media (max-width: #{$mobile-width}) {
		@content;
	}
}

@mixin mediaTablet {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin mediaMedium {
	@media (max-width: #{$medium-width}) {
		@content;
	}
}

@mixin lh($fontValue, $heightValue) {
	$my_width: calc($heightValue / $fontValue) * 100%;
	line-height: $my_width;
}

@function strip-units($number) {
	@return math.div($number, $number * 0 + 1)
}

@mixin clampf($min-fs, $max-fs, $min-w: 375px, $max-w: 1440px) {
	$mf: strip-units($max-fs - $min-fs);
	$mw: strip-units($max-w - $min-w);

	& {
		font-size: #{$min-fs};
		font-size: clamp($min-fs, calc(#{$min-fs} + #{$mf} * ((100vw - #{$min-w}) / #{$mw})), $max-fs);
	}

}

@mixin clamp($style, $min-fs, $max-fs, $min-w: 375px, $max-w: 1440px) {
	$mf: strip-units($max-fs - $min-fs);
	$mw: strip-units($max-w - $min-w);

	& {
		#{$style}: $max-fs;
		#{$style}: clamp($min-fs, calc(#{$min-fs} + #{$mf} * ((100vw - #{$min-w}) / #{$mw})), $max-fs);
	}

}